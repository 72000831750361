import React, { Component } from 'react';
// import ReactDOM from 'react-dom';
import Axios from 'axios';
import qs from 'qs';
import LS_SERVICE from '../../utils/localStorage';
import API_SERVICE from '../../utils/apiService';
import {
  EXISTING_CONDITION_LIST,
  FAMILY_HISTORY_LIST,
  SURGICAL_HISTORY_LIST,
  SOCIALHX_LIST,
  LOADER_BLACK,
  EXISTING_CONDITION_DELETE,
  FAMILY_HISTORY_DELETE,
  SURGICAL_HISTORY_DELETE,
  SOCIAL_HISTORY_DELETE,
  LOADER_RED,
  CONDITION_SEARCH,
  OBJECTIVE_STORE,
  DISEASE_SEARCH,
  RELATIONSHIP_SEARCH,
  FAMILY_STORE,
  SOCIAL_HISTORY_UPDATE,
  HANDLEVALIDANTMESSAGES,
  HANDLEVALIDANTMESSAGESPATIENTAGE,
  PATIENT_DATA,
  DURATION_TYPE,
  ISALLOWEDITSOAP,
  USERTYPES,
  TOGGLE_PRACTICE,
  CONDITION_ADD_MASTER,
  SEARCH_DRUGS,
  PRESCRIPTION_STORE,
  ADD_DRUGS,
  MEDICINES_GETALL_CURRENT_MEDICINES,
  PRESCRIPTION_DATA

} from '../../utils/constant';
import { Form, Modal, Input, Radio, Button, Select, AutoComplete } from 'antd';
import 'antd/dist/antd.css';
import { notification } from 'antd';
import moment from "moment";
import { jQueryLabels, FixedCta, AntSelectLabels, AntDisabledLabels, onlynumbers } from '../../utils/jQueryLabels';
import AsideLeft from '../../components/aside/asideleft';
import { headerService$, practiceService } from '../../utils/rxjs-sharing';
// import { element, object } from 'prop-types';

const { Option } = Select;
const { TextArea } = Input;

class History extends Component {

  constructor(props) {
    super(props)

    this.state = {
      patient: null,
      patientUrlData: null,
      isDeleteVisible: false,
      condition_duration_type: '',
      relationship_selected_data_id: '',
      DataLoaded: false,
      existingConditionData: {},
      existingConditionDurationData: {},
      familyHistoryData: {},
      surgicalHxData: {},
      socialHxData: {},
      socialHxHabitData: {},
      socialHxQuantityData: {},
      socialHxRouteData: {},
      socialHxStatusData: {},
      socialHxSinceTimeData: {},
      socialHxFrequencyData: {},
      socialHxTableShow: false,
      patientInfo: {},
      removeItemId: '',
      removeFormName: '',
      removeItemMessage: '',
      addConditionModal: false,
      addFamilyModal: false,
      addSurgicalModal: false,
      addSocialModal: false,
      conditionList: [],
      condition_selected_data: {},
      medication: '0',
      diseaseList: [],
      disease_selected_data: {},
      FamilyMemberList: [],
      FamilyMemberListLoaded: false,
      relationship_selected_data: {},
      formIsFetching: false,
      isAppointment: '0',
      habits: '0',
      habitStatus: '1',
      habit: '0',
      status: '1',
      time: '0',
      frequency: '0',
      quantity_unit: '0',
      route: '0',
      actionType: '',
      actionText: '',
      formModeEdit: false,
      habitStatusEdit: false,
      formFieldEntryId: null,
      fromFieldId: -1,
      onMedication: "0",
      patient_age: '',
      isPracticing: false,
      isPracticingModal: false,
      allowEdit: true,
      loginRole: '',
      isNurseLoggined: false,
      showAddConditionButton: false,
      disableAddConditionButton: false,
      searchDrugs: [],
      drug_id: '',
      drug_name: '',
      showAddDrugButton: true,
      formIsFetchingDrug: false,
      patient_prescription_drug_id:"",
      userId: LS_SERVICE.get("staff_id"),
			userName: LS_SERVICE.get("staff_name"),
      cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
    };

    this.onClick = this.removeItem.bind(this);
    this.onClick = this.deleteConfirmation.bind();
    this.conditionFormRef = React.createRef();
    this.familyMemberFormRef = React.createRef();
    this.surgicalHxFormRef = React.createRef();
    this.socialHxFormRef = React.createRef();

  }

  static getDerivedStateFromProps() {
    Axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    return null;
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const { hospital_id, patient_id, reference_id } = params;

    const isPracticingHospitalId = LS_SERVICE.get('practicing')?.hospital_id;

    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true
      })
    }

    if (LS_SERVICE.get('user_type') == USERTYPES.nurse) {
      this.setState({
        isNurseLoggined: true,
      });
    }


    const patientUrlData = {
      hospital_id,
      patient_id,
      qms_token_id: reference_id
    }
    let hospitalConfigData = LS_SERVICE.get('hospitalConfigData') ?? [];
    hospitalConfigData = hospitalConfigData.find(item => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData ? ISALLOWEDITSOAP(hospitalConfigData.pres_modification, hospitalConfigData.allow_hours, LS_SERVICE.get('appointmentCompletedTime')[reference_id], 'button') : true;

    // if(LS_SERVICE.get('user_type') == USERTYPES.nurse) {
    //     this.setState({allowEdit: true});
    // }

    this.setState({
      hospital_id,
      patient_id,
      patientUrlData,
      allowEdit
    }, () => {

      if (LS_SERVICE.has("hospitalConfigData") && LS_SERVICE.get('user_type') == USERTYPES.doctor) {
        const hospitalConfigData = LS_SERVICE.get('hospitalConfigData');
        let hospitalConfigObj = hospitalConfigData.find(obj => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' }); // IMPORTANT TO HIT AS PER THE VIEW
        this.getPatientData();
      } else {
        this.setState({ loginRole: 'nurse' }, () => {
          this.getPatientData();
        });
      }

    });
    this.getAllMedicineDetails(patient_id, reference_id);
    // if (this.props.location.state !== undefined) {
    //   this.setState({
    //     patient: this.props?.location?.state?.patient
    //   }, () => {
    //     this.loadData();
    //   })
    // } else {
    //   this.getPatientData()
    // }

    jQueryLabels();
    FixedCta();
    onlynumbers();
  }

  getPatientData = _ => {

    const { match: { params } } = this.props;
    const { patient_id, reference_id } = params;

    Axios.get(PATIENT_DATA({ patient_id }))
      .then(success => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results?.qmsToken?.qms_token_id || reference_id
        }
        this.setState({
          patient
        }, () => {
          this.loadData();
        })
      })
      .catch(err => console.log(err))
  }
  
  getAllMedicineDetails = (patient_id, reference_id) => {

    const PARAMS = {
      patient_id,
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id,
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
    }
    PARAMS.store_id = LS_SERVICE.get("STORE_ID");
    PARAMS.facility_id = LS_SERVICE.get("FACILITY_ID");
    PARAMS.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
   
    Axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id }))
      .then(success => {
        var addedDrugsList = [];
        let currentMedsList = success.data.data.details.current_medicines;
        currentMedsList.map((v, k) => { addedDrugsList.push(v.drug_id); });

        Axios.get(PRESCRIPTION_DATA(PARAMS))
          .then(success => {
            let { data } = success.data,
              drugId = data.details.map((v, k) => { addedDrugsList.push(v.drug_id)});
          }).catch(err => console.log(err.response));
         this.setState({
          addedDrugsListsId:addedDrugsList,
         })
      }).catch(err => console.log(err.response));

  };

  goBack = (event) => {
    event.preventDefault();
    this.props.history.goBack();
  }

  deleteModalCancel = () => {
    this.setState({ isDeleteVisible: false })
  }

  loadData = () => {
    const { patient } = this.state;

    const existingConditionPromise = Axios.get(EXISTING_CONDITION_LIST({ patient_id: patient.patient_id }));
    const familyHistorPromise = Axios.get(FAMILY_HISTORY_LIST + "/" + patient.patient_id);
    const surgicalHistoryPromise = Axios.get(SURGICAL_HISTORY_LIST({ patient_id: patient.patient_id }));
    const socialHxPromise = Axios.get(SOCIALHX_LIST + "/" + patient.patient_id);
    Axios.all([existingConditionPromise, familyHistorPromise, surgicalHistoryPromise, socialHxPromise])
      .then(Axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responseThree = responses[2];
        const responseFour = responses[3];
        //  console.log(responseFive);

        if (responseOne.data.hasOwnProperty("data") === true) {
          this.setState({
            existingConditionData: responseOne.data.data.details.conditions.patientConditions,
            existingConditionDurationData: responseOne.data.data.constants.pre_existing_condition_duration,
          })
        }

        if (responseTwo.data.hasOwnProperty("data") === true) {
          this.setState({
            familyHistoryData: responseTwo.data.data.record
          })
        }

        if (responseThree.data.data.details.hasOwnProperty("surgical_histories") === true) {
          this.setState({
            surgicalHxData: responseThree.data.data.details.surgical_histories.patientSurgicalHistories,
          })
        }

        if (responseFour.data.hasOwnProperty("data") === true) {
          this.setState({
            socialHxData: responseFour.data.data.record,
            socialHxFrequencyData: responseFour.data.data.frequency,
            socialHxHabitData: responseFour.data.data.habbits,
            socialHxQuantityData: responseFour.data.data.quantity,
            socialHxRouteData: responseFour.data.data.route,
            socialHxStatusData: responseFour.data.data.status,
            socialHxSinceTimeData: responseFour.data.data.since_time,
            socialHxTableShow: true,
          })
        }


        this.setState({
          patient_age: patient.age,
          DataLoaded: true,
        })

      }))

    this.fetchFamilyMemberData('');
  }

  // For Age Filter
  patientAgeFilter = (event, state) => {
    this.setState({
      [state]: event.target.value
    })
  }

  patientAgeFilterSelect = (e, state) => {
    this.setState({
      [state]: e
    });
  }

  // For Delete Item Modal open
  removeItem(event, id, formName) {
    event.preventDefault();

    const { isPracticing } = this.state;

    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      if (!isPracticing) {
        this.setState({
          isPracticingModal: true
        })
        return;
      }
    }

    this.setState({
      isDeleteVisible: true,
      removeItemId: id,
      removeFormName: formName
    });

    // For Existing Conditions
    if (formName === "Existing Conditions") {
      this.setState({ removeItemMessage: "Are you sure you want to delete this existing condition information?" })
    }

    // For Family Hx
    if (formName === "Family Hx") {
      this.setState({ removeItemMessage: "Are you sure you want to delete family history?" })
    }

    // For Surgical Hx
    if (formName === "Surgical Hx") {
      this.setState({ removeItemMessage: "Are you sure you want to delete surgical history?" })
    }

    // For Social Hx
    if (formName === "Social Hx") {
      this.setState({ removeItemMessage: "Are you sure you want to delete social history?" });
    }
  }

  handleMaxLimit = type => {
    if (type == '') {
      type = 3;
    }
    const { patient } = this.state;
    if (patient !== null) {
      const sD = moment(patient.dob);
      const eD = moment();
      const maxVal = eD.diff(sD, DURATION_TYPE[type]);
      return maxVal;
    }
  }

  deleteConfirmation = (event, id, formName) => {
    event.preventDefault();
    const history_id = id;
    const {userName, userId} = this.state;
    // For Existing Conditions
    if (formName === "Existing Conditions") {
      Axios.get(EXISTING_CONDITION_DELETE({ history_id, userName, userId }))
        .then(response => {
          this.handleExistingConditionData();
          this.setState({ isDeleteVisible: false });

          notification.success({
            message: response.data.message,
            placement: 'topRight'
          });
          //debugger;
        })
    }

    // For Family Hx
    if (formName === "Family Hx") {
      Axios.get(FAMILY_HISTORY_DELETE({ history_id, userName, userId }))
        .then(response => {
          //console.log(response);
          this.handleFamilyHxData();
          this.setState({ isDeleteVisible: false });

          notification.success({
            message: response.data.message,
            placement: 'topRight'
          });
          //debugger;
        })
    }

    // For Surgical Hx
    if (formName === "Surgical Hx") {
      Axios.get(SURGICAL_HISTORY_DELETE({ history_id, userName, userId }))
        .then(response => {
          //console.log(response);
          this.handlesurgicalHxData();
          this.setState({ isDeleteVisible: false });

          notification.success({
            message: response.data.message,
            placement: 'topRight'
          });
          //debugger;
        })
    }

    // For Social Hx 
    if (formName === "Social Hx") {
      Axios.get(SOCIAL_HISTORY_DELETE({ history_id, userName, userId }))
        .then(response => {
          //console.log(response);
          this.handleSocialHxData();
          this.setState({ isDeleteVisible: false });
          notification.success({
            message: response.data.message,
            placement: 'topRight'
          });
          //debugger;
        })
    }
  }

  // For Modal popup
  handleModalPopup = (e, popup, data = null, actionType = '') => {
    if (e !== null) e.preventDefault();

    const { isPracticing } = this.state;

    if (LS_SERVICE.get('user_type') == USERTYPES.doctor) {
      if (!isPracticing) {
        this.setState({
          isPracticingModal: true
        })
        return;
      }
    }

    this.setState({
      [popup]: !this.state[popup],
      drug_id: '',
      drug_name: ''
    }, () => {
      this.setState({
        habit: '0',
        habitStatus: '1',
        formModeEdit: false,
        habitStatusEdit: false,
        fromFieldId: -1,
        actionText: "Add",
        actionType
      }, () => {

        const { addConditionModal, addFamilyModal, addSurgicalModal, addSocialModal } = this.state;

        if (addConditionModal) {

          this.setState({
            onMedication: "0",
          })
          this.conditionFormRef.current.resetFields();
          this.conditionFormRef.current.setFieldsValue({
            condition_duration_type: "",
            onMedication: "0",
            medicineName: ''
          })

          if (actionType === "editConditions") {
            this.setState({
              actionText: "Update",
              formModeEdit: true,
              formFieldEntryId: data.conditionId,
              fromFieldId: data.id,
              onMedication: data.onMedication?.toString(),
              medicineName: data.medicineName,
              condition_duration_type: JSON.stringify(data.durationType),
              condition: data.condition_name
            })

            this.conditionFormRef.current.resetFields();
            this.conditionFormRef.current.setFieldsValue({
              condition_name: data.condition_name,
              condition_duration: data.duration,
              condition_duration_type: JSON.stringify(data.durationType),
              onMedication: data.onMedication?.toString(),
              medicineName: data.medicineName
            })
            setTimeout(function () {
              AntSelectLabels();
            }, 100);
          }
        }
        if (!addConditionModal) {
          this.setState({
            condition: '',
            showAddConditionButton: false,
            disableAddConditionButton: false,
          });
        }

        if (addFamilyModal) {
          this.familyMemberFormRef.current.resetFields();
          this.setState({
            disease_id: '',
            disease_name: '',
            relationship_selected_data_id: undefined
          })


          if (actionType === "editFamily") {

            this.setState({
              actionText: "Update",
              formModeEdit: true,
              disease_id: data.diseaseId,
              disease_name: data.diseaseName,
              fromFieldId: data.id,
              relationship_selected_data_id: data.relationshipId
            })

            this.familyMemberFormRef.current.resetFields();
            this.familyMemberFormRef.current.setFieldsValue({
              disease_name: data.diseaseName
            })
          }

          setTimeout(function () {
            AntSelectLabels();
          }, 100);
        }

        if (addSurgicalModal) {

          this.surgicalHxFormRef.current.resetFields();

          if (actionType === "editSurgical") {
            this.setState({
              actionText: "Update",
              formModeEdit: true,
              fromFieldId: data.id,
            })
            this.surgicalHxFormRef.current.setFieldsValue({
              surgery_name: data.reason,
              surgery_remarks: data.remarks,
            })

            setTimeout(function () {
              AntSelectLabels();
            }, 100);
          }
        }

        if (addSocialModal) {
          this.socialHxFormRef.current.resetFields();

          if (actionType === "editSocial") {
            this.setState({
              actionText: "Update",
              formModeEdit: true,
              fromFieldId: data.id,
              habit: JSON.stringify(data.details),
              time: JSON.stringify(data.time)
            })

            // this.socialHxFormRef.current.resetFields();
            this.socialHxFormRef.current.setFieldsValue({
              habit: JSON.stringify(data.details),
              status: JSON.stringify(data.options),
              since: JSON.stringify(data.since),
              time: JSON.stringify(data.time),
              frequency: JSON.stringify(data.frequency),
              quantity: JSON.stringify(data.quantity),
              quantity_unit: JSON.stringify(data.quantityUnit),
              drug_name: data.drugName,
              route: JSON.stringify(data.route)
            })

            if (JSON.stringify(data.options) === '0') {
              this.setState({ habitStatusEdit: true });
              this.socialHxFormRef.current.setFieldsValue({
                since: '',
                time: '0',
                frequency: '0',
                quantity: '',
              })

              if (JSON.stringify(data.details) === '0') {
                this.socialHxFormRef.current.setFieldsValue({
                  quantity_unit: '0'
                })
              }
              if (JSON.stringify(data.details) === '1' || JSON.stringify(data.details) === '3') {
                this.socialHxFormRef.current.setFieldsValue({
                  quantity_unit: '5'
                })
              }
              if (JSON.stringify(data.details) === '2') {
                this.socialHxFormRef.current.setFieldsValue({
                  quantity_unit: '7'
                })
              }
            }
          } else {
            this.socialHxFormRef.current.setFieldsValue({
              habit: "0",
              time: "0",
              status: "1",
              frequency: "0",
              quantity_unit: "0",
              route: "0",
            })
          }
        } else {
          this.setState({
            time: "0"
          })
        }
      })
    })

    setTimeout(function () {
      AntDisabledLabels()
    }, 100);
  }

  handleForRadioChange = d => {
    let { name, value } = d.currentTarget.hasOwnProperty('name') ? d.currentTarget : d.target;
    this.setState({
      [name]: value
    })

    if (this.socialHxFormRef.current) {
      const { since } = this.socialHxFormRef.current.getFieldValue();
      if (value === '1' && (since === '' || since === undefined)) {
        this.socialHxFormRef.current.setFieldsValue({
          time: '0'
        })
        this.setState({
          time: '0'
        })
      }

      if (value === '2' && (since === '' || since === undefined)) {
        this.socialHxFormRef.current.setFieldsValue({
          time: '3'
        })
        this.setState({
          time: '3'
        })
      }
    }

    if (this.state.actionType === 'editSocial') {
      this.setState({ habitStatusEdit: false })
    }
  }

  // ################################# For Start Existing Conditions ####################################################
  // For Condtitiondata Fetch
  fetchConditionData = async search => {
    this.setState({
      conditionList: [],
      condition: search,
    });
    const conditionDataPromise = await Axios.post(CONDITION_SEARCH, qs.stringify({ search }))
    if (conditionDataPromise.status) {
      let { result } = conditionDataPromise.data;
      this.setState({
        conditionList: result,
        showAddConditionButton: (search.length > 2 && result.length == 0) ? true : false,
      });
    }
  }

  handleAddCondition = async () => {
    const conditionAddPromise = await Axios.post(CONDITION_ADD_MASTER, { text: this.state.condition });
    this.setState({
      disableAddConditionButton: true
    });

    if (conditionAddPromise.status == 200) {
      let conditionList = this.state.conditionList;
      conditionList.push(conditionAddPromise.data);
      this.setState({
        disableAddConditionButton: false,
        showAddConditionButton: false,
        conditionList: conditionList,
      })
      this.handleOnConditionChange(conditionAddPromise.data.id, { children: conditionAddPromise.data.name, value: String(conditionAddPromise.data.id), dataid: conditionAddPromise.data });
    }
  }

  handleOnConditionChange = (condition, props) => {
    const condition_selected_data = {};
    Object.assign(condition_selected_data, props.dataid)
    this.setState({
      condition_selected_data,
      condition: props.dataid.name
    })

    this.conditionFormRef.current.setFieldsValue({
      condition_name: props.dataid.name,
    });

    document.getElementById("condition_duration").focus();
    AntSelectLabels();
  }

  // For Existing Conditions Add Submit

  handleConditionsAddSubmit = async _ => {

    const { condition_name, condition_duration, condition_duration_type, onMedication, medicineName } = this.conditionFormRef.current.getFieldValue();
    const { formFieldEntryId, formModeEdit, condition_selected_data, fromFieldId, patient } = this.state;
    const { patient_id, qms_token_id } = patient;
    let medicineType = 'F';
    if(this.state.onMedication !== undefined && this.state.onMedication !== '0' && this.state.onMedication !== '' && this.state.onMedication !== 'null'){
      if (this.state.drug_id == '' || this.state.drug_id == null) {
        let emailParams = {
            drug_name: this.state.drug_name,
        }

        await Axios.post(ADD_DRUGS, {
            hospital_id: LS_SERVICE.get('slot_hospital_id'),
            hospital_master: LS_SERVICE.get('slot_hospital_master'),
            name: this.state.drug_name,
            isCurrentMeds: true,
            emailParams: emailParams
        })
            .then(success => {
                this.setState({
                    drug_id: success.data.data.id,
                });
            }).catch(err => {
                console.log(err);
            })
      }

      if (!this.state.drug_id == '' && !this.state.drug_name == '') {
        const medicine_data = {
          drug_id: this.state.drug_id,
          drug_name: this.state.drug_name,
          prescription_drug_id: -1,
          active: 'Y',
          isOnlyCurrentMeds: 'Y'
        }
        const PRESCRIPTION_PARAMS = {
          reference_type: LS_SERVICE.get('reference_type'),
          reference_id: qms_token_id,
          patient_id,
          medicine_data: [medicine_data],
        };
      await  Axios.post(PRESCRIPTION_STORE, qs.stringify(PRESCRIPTION_PARAMS)).then(success => {
          let patient_prescription_drugId =success.data.storedDrugObject
          this.setState({
          drug_id: '',
          drug_name: '',
          patient_prescription_drug_id:patient_prescription_drugId[0].id
        })
        })
          .catch(err => console.log(err))
      }
    }
    const PARAMS = {
      type: 'condition',
      objective_details: [{
        condition: formModeEdit ? formFieldEntryId : condition_selected_data.id,
        condition_name: formModeEdit ? condition_name : this.state.condition,
        condition_duration,
        condition_duration_type,
        medication: onMedication,
        medicine_name: onMedication === '1' ? medicineName : "",
        id: fromFieldId,
        patient_prescription_drug_id:this.state.patient_prescription_drug_id
      }],
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      patient_id
    }
    debugger;
    const conditionAddDataPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS))

    if (conditionAddDataPromise.data.status) {
      this.handleExistingConditionData();
      this.handleModalPopup(null, 'addConditionModal');

      notification.success({
        message: conditionAddDataPromise.data.message,
        placement: 'topRight'
      });
      this.setState({
        patient_prescription_drug_id:""
      })

      this.conditionFormRef.current.resetFields();
    }
  }

  handleExistingConditionData = async () => {
    const { patient_id } = this.state.patient;
    const existingConditionPromise = await Axios.get(EXISTING_CONDITION_LIST({ patient_id }))
    this.setState({
      existingConditionData: existingConditionPromise.data.data.details.conditions.patientConditions,
      existingConditionDurationData: existingConditionPromise.data.data.constants.pre_existing_condition_duration
    })
  }



  // ################################# For Start Family Hx ####################################################

  // For Disease Fetch
  fetchDiseaseData = async search => {
    this.setState({ diseaseList: [] });
    const diseaseDataPromise = await Axios.post(DISEASE_SEARCH, qs.stringify({ search }))
    if (diseaseDataPromise.status) {
      let { result } = diseaseDataPromise.data;
      this.setState({ diseaseList: result });
    }
  }

  handleOnDiseaseChange = (disease, props) => {
    const disease_selected_data = {};
    Object.assign(disease_selected_data, props.dataid)
    this.setState({
      disease_selected_data
    })
    // }, () => console.log(this.state.disease_selected_data))

    document.getElementById("family_member").focus();
    AntSelectLabels();
  }


  // For Family Member Fetch
  fetchFamilyMemberData = async search => {
    this.setState({ FamilyMemberList: [] });
    const FamilyMemberPromise = await Axios.post(RELATIONSHIP_SEARCH, qs.stringify({ search }))
    if (FamilyMemberPromise.status) {
      let { result } = FamilyMemberPromise.data;
      this.setState({ FamilyMemberList: result, FamilyMemberListLoaded: true });
    }
  }

  handleOnRelationshipChange = (relationship, props) => {

    let relationship_selected_data_id = [];
    let relationship_selected_data_name = [];

    //  debugger;  
    props.forEach(element => {
      relationship_selected_data_id.push(element.dataid.id);
      relationship_selected_data_name.push(element.dataid.long);
    });

    this.setState({
      relationship_selected_data_id: relationship_selected_data_id.join(),
      relationship_selected_data_name: relationship_selected_data_name.join(),
    })
    document.getElementById("family_member").blur();
    AntSelectLabels();
  }

  // For Family Hx Add Submit
  handleFamilyHxAddSubmit = async _ => {
    const { formModeEdit, disease_id, disease_name, disease_selected_data, relationship_selected_data_id,
      relationship_selected_data_name, patient } = this.state;

    const PARAMS = {
      type: 'family',
      family_data: {
        disease_id: formModeEdit ? disease_id : disease_selected_data.id,
        disease_text: formModeEdit ? disease_name : disease_selected_data.name,
        family_member_id: relationship_selected_data_id,
        family_text: relationship_selected_data_name
      },
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: patient.qms_token_id,
      patient_id: patient.patient_id
    }

    const familyHxAddDataPromise = await Axios.post(FAMILY_STORE, qs.stringify(PARAMS))

    if (familyHxAddDataPromise.data.status) {
      this.handleFamilyHxData();
      this.handleModalPopup(null, 'addFamilyModal');

      notification.success({
        message: familyHxAddDataPromise.data.message,
        placement: 'topRight'
      });
      this.familyMemberFormRef.current.resetFields();
    }
  }

  handleFamilyHxData = async () => {
    const { patient_id } = this.state.patient;
    const familyHxPromise = await Axios.get(FAMILY_HISTORY_LIST + "/" + patient_id);
    if (familyHxPromise.data.hasOwnProperty("data") === true) {
      this.setState({
        familyHistoryData: familyHxPromise.data.data.record
      })
    }

  }

  // ################################# For Start Surgical Hx ####################################################

  // For Surgical Hx Add Submit
  handleSurgicalAddSubmit = async _ => {
    const { surgery_name, surgery_remarks } = this.surgicalHxFormRef.current.getFieldValue();
    const { fromFieldId, patient } = this.state;
    const PARAMS = {
      type: 'history',
      objective_details: [{
        history: surgery_name,
        remarks: surgery_remarks ? surgery_remarks : null,
        id: fromFieldId
      }],
      hospital_id: LS_SERVICE.get("slot_hospital_id"),
      speciality_id: LS_SERVICE.get("speciality_id"),
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: patient.qms_token_id,
      patient_id: patient.patient_id
    }
    // console.log(PARAMS);
    const surgicalAddDataPromise = await Axios.post(OBJECTIVE_STORE, qs.stringify(PARAMS))

    if (surgicalAddDataPromise.data.status) {
      this.handlesurgicalHxData();
      this.handleModalPopup(null, 'addSurgicalModal');

      notification.success({
        message: surgicalAddDataPromise.data.message,
        placement: 'topRight'
      });

      this.surgicalHxFormRef.current.resetFields();
    }
  }

  handlesurgicalHxData = async () => {
    const { patient_id } = this.state.patient;
    const surgicalPromise = await Axios.get(SURGICAL_HISTORY_LIST({ patient_id }))
    this.setState({
      surgicalHxData: surgicalPromise.data.data.details.surgical_histories.patientSurgicalHistories,
    })
  }
  // ################################# For Start Social Hx ###########################################
  // For Social Hx Change
  handleForSocialHxChange = d => {
    let { name, value } = d.currentTarget.hasOwnProperty('name') ? d.currentTarget : d.target;
    this.setState({ [name]: value })
    this.setState({
      habit: value,
      habitStatus: '1'
    }, () => {

      if (this.state.habit === "0") {
        this.socialHxFormRef.current.resetFields();
        this.socialHxFormRef.current.setFieldsValue({
          habit: "0",
          time: "0",
          status: "1",
          frequency: "0",
          quantity_unit: "0",
          route: "0"
        })
      }

      if (this.state.habit === "1") {
        this.socialHxFormRef.current.resetFields();
        this.socialHxFormRef.current.setFieldsValue({
          habit: "1",
          time: "0",
          status: "1",
          frequency: "0",
          quantity_unit: "6",
        })
      }

      if (this.state.habit === "2") {
        this.socialHxFormRef.current.resetFields();
        this.socialHxFormRef.current.setFieldsValue({
          habit: "2",
          time: "0",
          status: "1",
          frequency: "0",
          quantity_unit: "7",
        })
      }


      if (this.state.habit === "3") {
        this.socialHxFormRef.current.resetFields();
        this.socialHxFormRef.current.setFieldsValue({
          habit: "3",
          time: "0",
          status: "1",
          frequency: "0",
          quantity_unit: "7",
          route: '0'
        })
      }


    })
    // debugger;
  }



  // For Social Hx Add Submit
  handleSocialAddSubmit = async _ => {
    const { habit, status, since, time, frequency, quantity, quantity_unit, drug_name, route } = this.socialHxFormRef.current.getFieldValue();
    const { fromFieldId } = this.state;
    const routes = route!=null&& route!="null"?route:null;


    const PARAMS = {
      type: 'social',
      social_data: [{
        habit,
        status,
        since,
        time,
        frequency,
        quantity,
        quantity_unit,
        drug_name,
        routes,
        drug_id: fromFieldId
      }],
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.patient.qms_token_id,
      patient_id: this.state.patient.patient_id
    }

    //  console.log(PARAMS);
    const socialAddDataPromise = await Axios.post(SOCIAL_HISTORY_UPDATE, qs.stringify(PARAMS))

    if (socialAddDataPromise.data.status) {
      this.handleSocialHxData();
      this.handleModalPopup(null, 'addSocialModal');

      notification.success({
        message: socialAddDataPromise.data.message,
        placement: 'topRight'
      });

      this.setState({
        habit: '0',
        status: '1',
        time: '0',
        frequency: '0',
        quantity_unit: '0',
        route: '0',
      })

      this.socialHxFormRef.current.resetFields();
    }
  }

  handleSocialHxData = async () => {
    const { patient_id } = this.state.patient;
    const socialPromise = await Axios.get(SOCIALHX_LIST + "/" + patient_id);

    if (socialPromise.data.hasOwnProperty("data") === true) {
      this.setState({
        socialHxData: socialPromise.data.data.record,
        socialHxFrequencyData: socialPromise.data.data.frequency,
        socialHxHabitData: socialPromise.data.data.habbits,
        socialHxQuantityData: socialPromise.data.data.quantity,
        socialHxRouteData: socialPromise.data.data.route,
        socialHxStatusData: socialPromise.data.data.status,
        socialHxSinceTimeData: socialPromise.data.data.since_time,
        socialHxTableShow: true,
      })
    } else {
      this.setState({ socialHxTableShow: false })
    }
  }



  // ################################# For End Social Hx ###########################################
  nowCurrentDate = (days, units) => {
    let date_type = 'days';
    switch (parseInt(units)) {
      case 0:
        date_type = 'days';
        break;
      case 1:
        date_type = 'weeks';
        break;
      case 2:
        date_type = 'months';
        break;
      case 3:
        date_type = 'years';
        break;
    }

    let date = moment().subtract(days, date_type).format('MMM, YYYY');
    return date;
  }

  enablePractice = async e => {
    e.preventDefault();
    practiceService.init({ initiater: 'SOAP Pages', status: 'start' });
    const practice = await API_SERVICE.post(TOGGLE_PRACTICE, {
      hospital_id: this.state.hospital_id,
      practice_session: 'start'
    });

    let is_practicing;
    if (practice.data && practice.data.is_practicing === 'Y') {
      is_practicing = true;
      LS_SERVICE.set('practicing', {
        status: true,
        hospital_id: this.state.hospital_id
      });
    } else {
      LS_SERVICE.delete('practicing');
      is_practicing = false;
    }

    this.setState({
      isPracticing: is_practicing,
    });
    this.handleModalPopup(null, 'isPracticingModal');
  }

  handleObjectiveContinueForNurse = () => {
    this.props.history.push('/patient-queue/' + LS_SERVICE.get('slot_hospital_id'));
    return;
  }

  handleOnChange = (event) => {
    const { name, value } = event.target;

    if (name == 'condition_duration') {
      this.conditionFormRef.current.setFieldsValue({
        condition_duration_type: (name == 'condition_duration' && value != '' && value != null) ? "3" : ""
      });
    }
  };

  onKeyPress = (e) => {
    // Allow only numbers (0-9), backspace, and the decimal point
    const allowedChars = /[0-9\b.]/;

    // Check if the pressed key is allowed
    if (!allowedChars.test(e.key)) {
      e.preventDefault();
    }
  }

  onDrugssearch = async (search) => {
    await this.setState({
      formIsFetchingDrug: true
    })
    let params = { search: search };
    if (LS_SERVICE.get("user_type") == USERTYPES.admin) {
      params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null;
    } else if (LS_SERVICE.get("user_type") == USERTYPES.superadmin) {
      params.hospital_id = LS_SERVICE.get('master_hospital_id') ?? null;
    } else {
      params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    params.store_id = LS_SERVICE.get("STORE_ID");
    params.facility_id = LS_SERVICE.get("FACILITY_ID");
    params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
    if(!search || search == null || search == ''){
      this.setState({
        searchDrugs: LS_SERVICE.get("favouriteMedicines") || [],
        formIsFetching: false,
        showAddDrugButton: true
      });
    }
    else{
      await Axios.post(SEARCH_DRUGS, qs.stringify(params)).then(success => {
        this.setState({
          searchDrugs: success.data.medicine_list,
          formIsFetchingDrug: false,
          showAddDrugButton: (search.length > 2 && success.data?.medicine_list?.length == 0) ? false : true,
        })
      }).catch(err => console.log(err));
    }
  }

  onChangeDrugsSelect = async (value, props) => {
    await this.setState({
      drug_id: props.drug_id,
      drug_name: value,
    });
  }

  optionDrugLoad() {
    if (this.state.searchDrugs !== undefined && this.state.searchDrugs.length > 0) {
      return this.state.searchDrugs.map((obj, i) => {

        return (<Option key={i} value={obj.name} drug_id={obj.id} fav={obj.fav} disabled={(this.state.addedDrugsListsId && this.state.addedDrugsListsId.includes(obj.id))}>
          <span className="desc">
            {obj.name}
          </span>
          {/* <span className="float-right text-primary">
            {(LS_SERVICE.get("user_type") == USERTYPES.doctor) ?
              <i className={`icon_unique icon_star align-star ${obj.fav ? "favourite" : ""}`}></i>
              : null}
            {this.state.cimsallow ? (
              <Button onClick={(e) => { e.stopPropagation(); this.showModalInfo() }} className="nostyle-link p-0">
                <i className='icon_info'></i>
              </Button>
            ) : null}
          </span> */}
        </Option>
        )
      })
    } else return null
  };

  handleAddDrug = async () => {
    const { medicineName } =
    this.conditionFormRef.current.getFieldValue();
    this.setState({
      showAddDrugButton: true,
      drug_id: null,
      drug_name: medicineName,
  });
  }

  render() {
    const { patient, condition, conditionList, disease, diseaseList, relationship, FamilyMemberList, allowEdit } = this.state;
    let headerHabit = <thead id="socialHead">
      <tr>
        <th>Substance</th>
        <th>Status</th>
        <th>Since When</th>
        <th>Frequency</th>
        <th colSpan="2">Quantity</th>
        {allowEdit ? <th width="100" className="read_mode_actions" style={{ display: "table-cell" }}>Action</th> : null}
      </tr>
    </thead>;
    let headerDrug = <thead id="drugHead">
      <tr>
        <th>Drug Name</th>
        <th>Status</th>
        <th>Since When</th>
        <th>Frequency</th>
        <th>Quantity</th>
        <th>Route</th>
        {allowEdit ? <th width="100" className="read_mode_actions" style={{ display: "table-cell" }}>Action</th> : null}
      </tr>
    </thead>;
    let rowDrug = ''
    if (typeof this.state.socialHxData !== 'undefined' && Object.keys(this.state.socialHxData).length > 0) {
      rowDrug = this.state.socialHxData.filter(x => x.details === 3).map((socialObj) =>
        <tr id={`social_history_${socialObj.id}`} key={socialObj.id}>
          <td className="drugs_name">{socialObj.drugName}</td>
          <td className="habits_type">{this.state.socialHxStatusData[socialObj.options] || "NA"}</td>
          <td className="habits_duration">{socialObj.since} {this.state.socialHxSinceTimeData[socialObj.time] || "NA"}</td>
          <td className="habits_freq">{this.state.socialHxFrequencyData[socialObj.frequency] || "NA"}</td>
          <td className="habits_quant">{socialObj.quantity} {this.state.socialHxQuantityData[socialObj.quantityUnit] || "NA"}</td>
          <td className="habits_route">{this.state.socialHxRouteData[socialObj.route]}</td>
          {allowEdit ?
            <td style={{ textAlign: "center" }} className="action text-center">
              {patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
                <>
                  <a href="#" className="edit-link mr-2" title="Edit" onClick={e => this.handleModalPopup(e, 'addSocialModal', socialObj, 'editSocial')}></a>
                  <a href="#" title="Delete" onClick={(e) => this.removeItem(e, socialObj.id, 'Social Hx')}><i className="icon_delete"></i></a>
                </> : null}
            </td>
            : null}
        </tr>
      )
    }
    return (
      <>

        {/* <!-- Page sidebar--> */}
        {(patient != undefined && patient.patient_id != undefined) ? <AsideLeft patient_id={patient.patient_id} reference_id={patient.qms_token_id} /> : null}

        <div className="content-wrapper">
          <div className="container-fluid">
            {this.state.DataLoaded === true ? (
              <>
                <div className="row emrfrm">
                  <div className="col-12 mb-3">
                    <div className="float-left">
                      <h1 className="page-title">History</h1>
                    </div>
                    <div className="float-right">
                      <a href="#" className="btn btn-outline-secondary px-4 small text-uppercase" onClick={this.goBack}>BACK</a>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div className="card border-0 bg-light h-100">
                      <div className="card-header border-0 p-0 bg-white">
                        <div className="row">
                          <div className="col-12 mb-3">
                            <h5 className="page-title float-md-left mt-2 mb-0">Existing Conditions</h5>
                            {allowEdit ?
                              <div className="float-md-right">
                                {patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
                                  <button className="btn btn-outline-primary read_mode_actions text-uppercase" onClick={e => this.handleModalPopup(e, 'addConditionModal')}>+ Conditions</button>
                                  : null}
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <ul className="list-group list-group-flush" id="condition_list">
                          {
                            typeof this.state.existingConditionData !== 'undefined' && Object.keys(this.state.existingConditionData).length > 0 ?
                              this.state.existingConditionData.map(ConditionObj =>
                                <li className="list-group-item" id={`existing_conditions_${ConditionObj.id}`} key={ConditionObj.id}>
                                  {ConditionObj.condition_name}
                                  <p className="text-primary">{ConditionObj.duration} {this.state.existingConditionDurationData[ConditionObj.durationType]}</p>
                                  <p>Medication: {ConditionObj.onMedication === 1 ? "Yes" : "No"}</p>
                                  {ConditionObj.onMedication === 1 ?
                                    <p>Medicine Name: {ConditionObj.medicineName} </p>
                                    : null}
                                  {patient.qms_token_id !== null && patient.qms_token_id !== undefined && allowEdit ?
                                    <>
                                      <a href="#" className="edit-link" title="Edit" onClick={e => this.handleModalPopup(e, 'addConditionModal', ConditionObj, 'editConditions')}></a>
                                      <a href="#" title="Delete" className="delete-relation-right" onClick={(e) => this.removeItem(e, ConditionObj.id, 'Existing Conditions')}></a>
                                    </> : null}
                                </li>
                              ) : ''
                            // <li className="list-group-item">Info not available.</li>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div className="card border-0 bg-light h-100">
                      <div className="card-header border-0 p-0 bg-white">
                        <div className="row">
                          <div className="col-12 mb-3">
                            <h5 className="page-title float-md-left mt-2 mb-0">Family Hx</h5>
                            {allowEdit ?
                              <div className="float-md-right">
                                {patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
                                  <button className="btn btn-outline-primary read_mode_actions text-uppercase" id="add-family_btn" onClick={e => this.handleModalPopup(e, 'addFamilyModal')}>+ Family History</button>
                                  : null}
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <ul className="list-group list-group-flush" id="family_list">
                          {
                            typeof this.state.familyHistoryData !== 'undefined' && Object.keys(this.state.familyHistoryData).length > 0 ?
                              this.state.familyHistoryData.map(familyObj =>
                                <li className="list-group-item" id={`family_hx_${familyObj.id}`} key={familyObj.id}>
                                  <span>{familyObj.diseaseName} - {(familyObj.relationshipName).replace(/,/g, ", ")}</span>
                                  {patient.qms_token_id !== null && patient.qms_token_id !== undefined && allowEdit ?
                                    <>
                                      <a href="#" className="edit-link" title="Edit" onClick={e => this.handleModalPopup(e, 'addFamilyModal', familyObj, 'editFamily')}></a>
                                      <a href="#" title="Delete" className="delete-relation-right" onClick={(e) => this.removeItem(e, familyObj.id, 'Family Hx')}></a>
                                    </> : null}
                                </li>
                              ) : ''
                            // <li className="list-group-item">Info not available.</li>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 mb-3">
                    <div className="card border-0 bg-light h-100">
                      <div className="card-header border-0 p-0 bg-white">
                        <div className="row">
                          <div className="col-12 mb-3">
                            <h5 className="page-title float-md-left mt-2 mb-0">Surgical Hx</h5>
                            {allowEdit ?
                              <div className="float-md-right">
                                {patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
                                  <button className="btn btn-outline-primary read_mode_actions text-uppercase" id="add-surgical_btn" onClick={e => this.handleModalPopup(e, 'addSurgicalModal')}>+ Surgical History</button>
                                  : null}
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <ul className="list-group list-group-flush" id="surgical_list">
                          {
                            typeof this.state.surgicalHxData !== 'undefined' && Object.keys(this.state.surgicalHxData).length > 0 ?
                              this.state.surgicalHxData.map(surgicalObj =>
                                <li className="list-group-item" id={`surgical_history_${surgicalObj.id}`} key={surgicalObj.id}>
                                  {surgicalObj.reason}
                                  <p className="text-primary">{surgicalObj.remarks}</p>
                                  {patient.qms_token_id !== null && patient.qms_token_id !== undefined && allowEdit ?
                                    <>
                                      <a href="#" className="edit-link" title="Edit" onClick={e => this.handleModalPopup(e, 'addSurgicalModal', surgicalObj, 'editSurgical')}></a>
                                      <a href="#" title="Delete" className="delete-relation-right" onClick={(e) => this.removeItem(e, surgicalObj.id, 'Surgical Hx')}></a>
                                    </> : null}
                                </li>
                              ) : ''
                            // <li className="list-group-item">Info not available.</li>
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row emrfrm my-3">
                  <div className="col-md-12">
                    <h5 className="float-left page-title mt-2 mb-0">Social Hx</h5>
                    {allowEdit ?
                      <div className="float-right">
                        {patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
                          <button id="add_habits_btn" className="float-md-right btn btn-outline-primary read_mode_actions text-uppercase" onClick={e => this.handleModalPopup(e, 'addSocialModal')}>+ Social History</button>
                          : null}
                      </div>
                      : null}
                  </div>
                </div>
                <div className="row pb-4">
                  <div className="col-md-12">
                    {this.state.socialHxTableShow === true ? (
                      <>
                        <div className="table-responsive">
                          <table className="table table-bordered" id="social_hx_list">

                            {typeof this.state.socialHxData !== 'undefined' && this.state.socialHxData.filter(x => x.details !== 3).length > 0 ? (
                              <>
                                {headerHabit}
                              </>
                            ) : null}

                            <tbody id="substanceOne">
                              {

                                typeof this.state.socialHxData !== 'undefined' && Object.keys(this.state.socialHxData).length > 0 ?
                                  this.state.socialHxData.filter(x => x.details !== 3).map((socialObj, index) =>
                                  (
                                    <tr id={`social_history_${socialObj.id}`} key={socialObj.id}>
                                      <td>{this.state.socialHxHabitData[socialObj.details] || ""}</td>
                                      <td className="habits_type">{this.state.socialHxStatusData[socialObj.options] || "NA"}</td>
                                      <td className="habits_duration">{socialObj.since} {this.state.socialHxSinceTimeData[socialObj.time] || "NA"}</td>
                                      <td className="habits_freq">{this.state.socialHxFrequencyData[socialObj.frequency] || "NA"}</td>
                                      <td className="habits_quant" colSpan="2">{socialObj.quantity} {this.state.socialHxQuantityData[socialObj.quantityUnit] || "NA"}</td>
                                      {allowEdit ?
                                        <td style={{ textAlign: "center" }} className="action text-center">
                                          {patient.qms_token_id !== null && patient.qms_token_id !== undefined ?
                                            <>
                                              <a href="#" className="edit-link mr-2" title="Edit" onClick={e => this.handleModalPopup(e, 'addSocialModal', socialObj, 'editSocial')}></a>
                                              <a href="#" title="Delete" onClick={(e) => this.removeItem(e, socialObj.id, 'Social Hx')}><i className="icon_delete"></i></a>
                                            </> : null}
                                        </td>
                                        : null}
                                    </tr>
                                  )) : null}
                            </tbody>


                            {typeof this.state.socialHxData !== 'undefined' && this.state.socialHxData.filter(x => x.details === 3).length > 0 ? (
                              <>
                                {headerDrug}
                                <tbody id="substanceTwo">{rowDrug}</tbody>
                              </>
                            ) : null
                            }
                          </table>
                        </div>
                      </>
                    ) : ''
                      // 'Info not available.'
                    }
                  </div>
                </div>
              </>
            ) : (
              <div className="row">
                <div className="col-12 text-center mt-2">{LOADER_RED}</div>
              </div>

            )
            }

          </div>
        </div>


        {/* Modal For Add Condition  */}
        <Modal
          destroyOnClose={true}
          title={this.state.actionText + " Condition"}
          visible={this.state.addConditionModal}
          onCancel={(e) => this.handleModalPopup(e, 'addConditionModal')}
          width="600px"
          bodyStyle={{ paddingBottom: '10px' }}
          className="emrfrm"
          footer={false}
        >

          <Form
            ref={this.conditionFormRef}
            validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
            onFinish={this.handleConditionsAddSubmit}
            layout="vertical">
            <div className="antSelectItem mt-2">
              <div className="form-group add_buttons font-col">
                <Form.Item name="condition_name" label="Condition"
                  rules={[{
                    required: true,
                    message: 'Please choose condition',
                  }]}
                >
                  {/* {(this.state.showAddConditionButton) ?
                    <button className={"btn btn-outline-primary"} disabled={this.state.disableAddConditionButton} onClick={() => this.handleAddCondition()}>ADD</button>
                    :
                    null
                  } */}
                  <AutoComplete
                    id="condition_name"
                    name="condition_name"
                    showSearch
                    placeholder=" "
                    showArrow={!this.state.showAddConditionButton}
                    value={condition}
                    notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                    loading={this.state.formIsFetching}
                    filterOption={false}
                    onSearch={this.fetchConditionData}
                    onSelect={this.handleOnConditionChange}
                    style={{ width: "100%" }}
                    disabled={this.state.formModeEdit}
                  >
                    {/* <Select
                    // name="condition_name"
                    showSearch
                    value={condition}
                    notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                    filterOption={false}
                    onSearch={this.fetchConditionData}
                    onChange={this.handleOnConditionChange}
                    style={{ width: '100%' }}
                    disabled={this.state.formModeEdit}
                  > */}
                    {conditionList !== undefined && conditionList.length > 0 ? conditionList.map(d => (
                      <Option dataid={d} key={d.id}>{d.name}</Option>
                    )) : null}
                    {/* </Select> */}
                  </AutoComplete>
                </Form.Item>
              </div>
            </div>
            <div className="antInputItem">
              <Form.Item
                name="condition_duration"
                label="Duration"
                // className="onlynumbers"
                rules={[{
                  type: 'number',
                  // required: true,
                  min: 0,
                  // max: +this.state.condition_duration_type === 3 ? this.state.patient_age : 99,
                  max: this.handleMaxLimit(this.state.condition_duration_type),
                  transform: function (value) {
                    return value === "" || value === undefined ? value : +parseFloat(value)
                  }
                }]}
              >
                <Input maxLength="5" autoComplete="off" name="condition_duration" onChange={this.handleOnChange} onKeyPress={this.onKeyPress}/>
              </Form.Item>
            </div>
            <Form.Item name="condition_duration_type" className="mt-4" >
              <Radio.Group onChange={(e) => { this.patientAgeFilter(e, "condition_duration_type"); this.conditionFormRef.current.validateFields() }}>
                <Radio value="0" className="mr-4">Days ago</Radio>
                <Radio value="1" className="mr-4">Weeks ago</Radio>
                <Radio value="2" className="mr-4">Months ago</Radio>
                <Radio value="3">Years ago</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item name="onMedication" label={<strong>On Medication?</strong>} className="mt-3" onChange={this.handleForRadioChange}>
              <Radio.Group name="onMedication">
                <Radio value="1" className="mr-4">Yes</Radio>
                <Radio value="0">No</Radio>
              </Radio.Group>
            </Form.Item>
            {this.state.onMedication !== undefined && this.state.onMedication !== '0' && this.state.onMedication !== '' && this.state.onMedication !== 'null' ? (
            <div className="antSelectItem mt-2">
              <div className="form-group add_buttons font-col">
                {(this.state.showAddDrugButton) ?
                  null
                  :
                  <a className={"btn btn-outline-primary"} disabled={this.state.showAddDrugButton} onClick={() => this.handleAddDrug()}>ADD</a>
                }
                <Form.Item name='medicineName' label='Medicine Name' rules={[{
                  required: true,
                  message: 'Please choose medicine name',
                }]}>
                  <AutoComplete
                    id='medicineName'
                    name='medicineName'
                    style={{ width: 100 + '%' }}
                    showSearch={true}
                    showArrow={this.state.showAddDrugButton}
                    notFoundContent={this.state.formIsFetchingDrug ? LOADER_BLACK : null}
                    loading={this.state.formIsFetchingDrug}
                    onSelect={this.onChangeDrugsSelect}
                    onSearch={this.onDrugssearch}
                    className="select-drugs"
                    value={this.state.drug_name}
                    filterOption={false}
                    dropdownClassName="custom_drug_select"
                    autoFocus={this.state.drug_name != null && this.state.drug_name != '' ? false : true}
                    showAction={this.state.drug_name != null && this.state.drug_name != '' ? [] : ['focus', 'click']}
                    disabled={this.state.formModeEdit}
                  >
                    {this.optionDrugLoad()}
                  </AutoComplete>
                </Form.Item>
              </div>
            </div>
            ):null}
             {/* {this.state.onMedication !== undefined && this.state.onMedication !== '0' && this.state.onMedication !== '' && this.state.onMedication !== 'null' ? (
              <div className="antInputItem">
                <Form.Item
                  rules={[{
                    required: true
                  }]}
                  name="medicineName"
                  label="Medicine Name"
                >
                  <Input placeholder="Medicine Name" />
                </Form.Item>
              </div>
            ) : null} */}
            <Form.Item className="text-center">
              <Button disabled={!this.state.condition} type="primary" htmlType="submit" className="px-4">SAVE </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal For Family Hx */}
        <Modal
          title={this.state.actionText + " Family History"}
          visible={this.state.addFamilyModal}
          onCancel={(e) => this.handleModalPopup(e, 'addFamilyModal')}
          bodyStyle={{ paddingBottom: '15px' }}
          className="emrfrm"
          footer={false}
        >

          <Form
            ref={this.familyMemberFormRef}
            validateMessages={HANDLEVALIDANTMESSAGES}
            onFinish={this.handleFamilyHxAddSubmit}
            layout="vertical">

            <div className="antSelectItem">
              <Form.Item name="disease_name" label="Disease name"
                rules={[{
                  required: true,
                  message: 'Please choose disease',
                }]}
              >
                <Select
                  showSearch
                  value={disease}
                  autoFocus
                  notFoundContent={this.state.formIsFetching ? LOADER_BLACK : null}
                  loading={this.state.formIsFetching}
                  filterOption={false}
                  onSearch={this.fetchDiseaseData}
                  onChange={this.handleOnDiseaseChange}
                  style={{ width: '100%' }}
                  disabled={this.state.formModeEdit}
                >
                  {diseaseList !== undefined && diseaseList.length > 0 ? diseaseList.map(d => (
                    <Option dataid={d} key={d.id}>{d.name}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>

            <div className="antSelectItem">
              <Form.Item name="family_member" label="Family Member"
                rules={[{
                  required: true,
                  message: 'Please choose family member',
                }]}
              >
                {/* mode="multiple" */}
                <Select
                  showSearch
                  mode="multiple"
                  value={relationship}
                  notFoundContent={null}
                  filterOption={true}
                  // onSearch={this.fetchFamilyMemberData}
                  onChange={this.handleOnRelationshipChange}
                  defaultValue={this.state.relationship_selected_data_id !== undefined ? this.state.relationship_selected_data_id.split(',') : []}
                  style={{ width: '100%' }}
                  optionFilterProp="children"
                >
                  {this.state.FamilyMemberListLoaded && FamilyMemberList !== undefined && FamilyMemberList.length > 0 ? FamilyMemberList.map(d => (
                    <Option dataid={d} key={d.id}>{d.long}</Option>
                  )) : null}
                </Select>
              </Form.Item>
            </div>

            <Form.Item className="text-center mt-4">
              <Button type="primary" htmlType="submit" className="px-4">SAVE </Button>
            </Form.Item>
          </Form>
        </Modal>


        {/* Modal For Surgical History */}
        <Modal
          title={this.state.actionText + " Surgical History"}
          visible={this.state.addSurgicalModal}
          onCancel={(e) => this.handleModalPopup(e, 'addSurgicalModal')}
          width="600px"
          bodyStyle={{ paddingBottom: '15px' }}
          className="emrfrm"
          footer={false}
        >

          <Form ref={this.surgicalHxFormRef} validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE} onFinish={this.handleSurgicalAddSubmit}
            layout="vertical">
            <div className="antInputItem">
              <Form.Item name="surgery_name" label="Surgery Name"
                rules={[
                  { required: true, message: 'Please enter surgery' },
                  // { pattern: /^(?=[a-zA-Z\d\s]{0,48}$)([a-zA-Z01-9]+ ?)*$/, message: 'Maximum 50 characters.' },
                  // ^\s*(?:\S\s*){25,50}$
                ]}
              >
                {/* <Input  maxLength={50}/> */}
                <TextArea  rows={3} />
              </Form.Item>
            </div>

            <div className="antInputItem">
              <Form.Item name="surgery_remarks" label="Remarks"
              >
                <TextArea name="surgery_remarks" onChange={this.handleOnChange} rows={3} />
              </Form.Item>
            </div>

            <Form.Item className="text-center mt-2">
              <Button type="primary" htmlType="submit" className="px-4">SAVE </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Modal For Social  History */}
        <Modal
          title={this.state.actionText + " Social History"}
          visible={this.state.addSocialModal}
          onCancel={(e) => this.handleModalPopup(e, 'addSocialModal')}
          width="700px"
          bodyStyle={{ paddingBottom: '15px' }}
          className="emrfrm"
          footer={false}
        // closable={false}
        >

          <Form
            ref={this.socialHxFormRef}
            validateMessages={HANDLEVALIDANTMESSAGESPATIENTAGE}
            onFinish={this.handleSocialAddSubmit}
            layout="vertical">

            <Form.Item
              name="habit" onChange={this.handleForSocialHxChange}>
              <Radio.Group name="habit">
                {!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '0') ? (
                  <Radio value="0" className="mr-4">Smoking</Radio>
                ) : null}

                {!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '1') ? (
                  <Radio value="1" className="mr-4">Tobacco</Radio>
                ) : null}

                {!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '2') ? (
                  <Radio value="2" className="mr-4">Alcohol</Radio>
                ) : null}

                {!this.state.formModeEdit || (this.state.formModeEdit && this.state.habit === '3') ? (
                  <Radio value="3">Drugs</Radio>
                ) : null}
              </Radio.Group>
            </Form.Item>

            {/* For smoking Tab */}
            {this.state.habit === '0' ? (
              <>
                <div className="ant-row">
                  <div className="col-5 pl-0">
                    <Form.Item name="status" label={<strong>Do you smoke?</strong>} onChange={this.handleForRadioChange}>
                      <Radio.Group name="habitStatus" value={this.state.status}>
                        <Radio value="1" className="mr-4">Yes</Radio>
                        <Radio value="0" className="mr-4">No</Radio>
                        <Radio value="2">Quit</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when have you been smoking?" : "When did you quit smoking?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item
                            name="since"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: this.handleMaxLimit(this.state.time),
                              // message: "Duration cannot be greater than patient's age",
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}
                          >
                            <Input className="onlynumbers" maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="time">
                            <Select style={{ width: 120 }} defaultValue={this.state.time} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
                              <Option value="0">Days</Option>
                              <Option value="1">Weeks</Option>
                              <Option value="2">Months</Option>
                              <Option value="3">Years</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


                <div className="ant-row">
                  <div className="col-5 pl-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <Form.Item name="frequency" label={<strong>Frequency</strong>}>
                        <Radio.Group defaultValue={this.state.frequency}>
                          <Radio value="0" className="mr-4">Daily</Radio>
                          <Radio value="1" className="mr-4">Occasionally</Radio>
                        </Radio.Group>
                      </Form.Item>
                    ) : ""}
                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "Quantity" : "What quantity were you smoking?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="quantity" className="form-label-group input-group"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: 99,
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}
                          >
                            <Input className="onlynumbers" maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="quantity_unit">
                            <Select name="quantity_unit" style={{ width: 120 }}
                              defaultValue={this.state.quantity_unit}
                            >
                              <Option value="0">Cigarettes</Option>
                              <Option value="1">Cigars</Option>
                              <Option value="2">Bidis</Option>
                              <Option value="3">Hookahs</Option>
                              <Option value="4">Pipes</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


              </>
            ) : null}

            {/* For Tobacco Tab */}
            {this.state.habit === '1' ? (
              <>
                <div className="ant-row">
                  <div className="col-5 pl-0">
                    <Form.Item name="status" label={<strong>Do you take tobacco?</strong>} onChange={this.handleForRadioChange}>
                      <Radio.Group name="habitStatus" defaultValue={this.state.status}>
                        <Radio value="1" className="mr-4">Yes</Radio>
                        <Radio value="0" className="mr-4">No</Radio>
                        <Radio value="2">Quit</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </div>
                  <div className="col-7 pr-0">

                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when have you been taking tobacco?" : "When did you quit tobacco?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="since" className="onlynumbers"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: this.handleMaxLimit(this.state.time),
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}

                          >
                            <Input className="onlynumbers" maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="time">
                            <Select style={{ width: 120 }} defaultValue={this.state.time} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
                              <Option value="0">Days</Option>
                              <Option value="1">Weeks</Option>
                              <Option value="2">Months</Option>
                              <Option value="3">Years</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


                <div className="ant-row">
                  <div className="col-5 pl-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <Form.Item name="frequency" label={<strong>Frequency</strong>}>
                        <Radio.Group defaultValue={this.state.frequency}>
                          <Radio value="0" className="mr-4">Daily</Radio>
                          <Radio value="1" className="mr-4">Occasionally</Radio>
                        </Radio.Group>
                      </Form.Item>
                    ) : ""}
                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "What amount of tobacco are you taking?" : "What amount of tobacco were you taking?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="quantity" className="form-label-group input-group"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: 99,
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}
                          >
                            <Input className="onlynumbers" maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="quantity_unit">
                            <Select name="quantity_unit" style={{ width: 120 }}
                              defaultValue={this.state.quantity_unit}
                            >
                              <Option value="5">mg</Option>
                              <Option value="6">gm</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


              </>
            ) : null}

            {/* For Alcohol Tab */}
            {this.state.habit === '2' ? (
              <>
                <div className="ant-row">
                  <div className="col-5 pl-0">
                    <Form.Item name="status" label={<strong>Do you consume alcohol?</strong>} onChange={this.handleForRadioChange}>
                      <Radio.Group name="habitStatus" defaultValue={this.state.status}>
                        <Radio value="1" className="mr-4">Yes</Radio>
                        <Radio value="0" className="mr-4">No</Radio>
                        <Radio value="2">Quit</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when have you been consuming alcohol?" : "When did you quit alcohol?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="since" className="onlynumbers"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: this.handleMaxLimit(this.state.time),
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}

                          >
                            <Input className="onlynumbers" maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="time">
                            <Select style={{ width: 120 }} defaultValue={this.state.time} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
                              <Option value="0">Days</Option>
                              <Option value="1">Weeks</Option>
                              <Option value="2">Months</Option>
                              <Option value="3">Years</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


                <div className="ant-row">
                  <div className="col-5 pl-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <Form.Item name="frequency" label={<strong>Frequency</strong>}>
                        <Radio.Group defaultValue={this.state.frequency}>
                          <Radio value="0" className="mr-4">Daily</Radio>
                          <Radio value="1" className="mr-4">Occasionally</Radio>
                        </Radio.Group>
                      </Form.Item>
                    ) : ""}
                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "What amount of alcohol are you consuming?" : "What amount of alcohol were you consuming?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="quantity" className="form-label-group input-group"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: 999,
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}
                          >
                            <Input className="onlynumbers" maxLength="3" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="quantity_unit">
                            <Select name="quantity_unit" style={{ width: 120 }} defaultValue={this.state.quantity_unit}
                            >
                              <Option value="7">ml</Option>
                              <Option value="8">liter</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


              </>
            ) : null}

            {/* For Drugs Tab */}
            {this.state.habit === '3' ? (
              <>
                <div className="ant-row">
                  <div className="col-5 pl-0">
                    <Form.Item name="status" label={<strong>Have you ever used recreational drugs?</strong>} onChange={this.handleForRadioChange}>
                      <Radio.Group name="habitStatus" defaultValue={this.state.status}>
                        <Radio value="1" className="mr-4">Yes</Radio>
                        <Radio value="0" className="mr-4">No</Radio>
                        <Radio value="2">Quit</Radio>
                      </Radio.Group>
                    </Form.Item>

                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "Since when did you start using recreational drugs?" : "When did you quit?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="since" className="onlynumbers"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: this.handleMaxLimit(this.state.time),
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}

                          >
                            <Input maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="time">
                            <Select style={{ width: 120 }} onChange={(e) => { this.patientAgeFilterSelect(e, "time"); this.socialHxFormRef.current.validateFields() }}>
                              <Option value="0">Days</Option>
                              <Option value="1">Weeks</Option>
                              <Option value="2">Months</Option>
                              <Option value="3">Years</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>


                {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                  <>
                    <div className="ant-row">
                      <div className="col-5 pl-0">
                        <Form.Item name="drug_name" label={<strong>Name of drug</strong>} className="form-label-group input-group"
                          rules={[{
                            required: true,
                            message: 'Please enter drug',
                          }]}

                        >
                          <Input />
                        </Form.Item>
                      </div>
                      <div className="col-7 pr-0">
                        <Form.Item name="route" label={<strong>Route</strong>}>
                          <Radio.Group defaultValue={this.state.route} className="mt-2">
                            <Radio value="0" className="mr-4">Oral</Radio>
                            <Radio value="1" className="mr-4">IV</Radio>
                            <Radio value="2">Others</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                    </div>


                  </>
                ) : ''}

                <div className="ant-row">
                  <div className="col-5 pl-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <Form.Item name="frequency" label={<strong>Frequency</strong>}>
                        <Radio.Group defaultValue={this.state.frequency}>
                          <Radio value="0" className="mr-4">Daily</Radio>
                          <Radio value="1" className="mr-4">Occasionally</Radio>
                        </Radio.Group>
                      </Form.Item>
                    ) : ""}
                  </div>
                  <div className="col-7 pr-0">
                    {!this.state.habitStatusEdit && this.state.habitStatus !== "0" ? (
                      <div className="ant-row ant-input-select">
                        <label className="col-12"><strong>{this.state.habitStatus === "1" ? "Quantity" : "What quantity of drug were you taking?"}</strong></label>
                        <div className="col pr-0">
                          <Form.Item name="quantity" className="form-label-group input-group"
                            rules={[{
                              required: true,
                              type: 'number',
                              min: 0,
                              max: 99,
                              transform: function (value) {
                                return value === "" || value === undefined ? value : +value
                              }
                            }]}
                          >
                            <Input className="onlynumbers" maxLength="2" />
                          </Form.Item>
                        </div>
                        <div className="col-auto pl-0">
                          <Form.Item name="quantity_unit">
                            <Select name="quantity_unit" style={{ width: 120 }} defaultValue={this.state.quantity_unit}>
                              <Option value="5">mg</Option>
                              <Option value="6">gm</Option>
                              <Option value="7">ml</Option>
                              <Option value="8">litre</Option>
                            </Select>
                          </Form.Item>

                        </div>
                      </div>
                    ) : ''}

                  </div>
                </div>
              </>
            ) : null}


            <Form.Item className="text-center mt-2">
              {/* <Button type="default" className="mr-3 px-4" onClick={(e) => this.handleModalPopup(e, 'addSocialModal')}>CANCEL </Button> */}
              <Button type="primary" htmlType="submit" className="px-4">SAVE </Button>
            </Form.Item>
          </Form>

        </Modal>

        {/* Modal For Delete Confirmation  */}
        <Modal
          title=""
          visible={this.state.isDeleteVisible}
          onCancel={() => this.deleteModalCancel()}
          // width= "550px"
          closable={false}
          footer={false}
        >
          <form className="emrfrm">
            <div className="row mb-2">
              <div className="col-12 my-3 text-center"><h6>{this.state.removeItemMessage}</h6></div>
              <div className="col-md-12 text-center">
                <button type="button" className="btn btn-outline-secondary px-5 mr-1 mb-3 mb-sm-0" onClick={this.deleteModalCancel}>CANCEL</button>
                <button type="button" id="hx-modal-modal-btn" className="btn btn-primary px-5 ml-1" onClick={(e) => this.deleteConfirmation(e, this.state.removeItemId, this.state.removeFormName)}>DELETE</button>
              </div>
            </div>

          </form>
        </Modal>

        {/** || isPracticing Modal */}
        <Modal
          title={false}
          closable={false}
          visible={this.state.isPracticingModal}
          footer={false}
        >
          <div className="row">
            <div className="col-12 my-3 text-center">
              <h6>Do you want to Start Practice?</h6>
            </div>
          </div>
          <div className="modal-footer justify-content-center">
            <div className="btn btn-outline-secondary px-5" onClick={e => this.setState({ isPracticingModal: false })}>No</div>
            <div className="btn btn-primary px-5" onClick={this.enablePractice}>Yes</div>
          </div>
        </Modal>


        <div className="fixed-cta">
          {this.state.isNurseLoggined ? (
            <button
              className="btn btn-primary px-4 nostyle-link"
              onClick={this.handleObjectiveContinueForNurse}
            >
              CONTINUE
            </button>
          ) : null}

        </div>



      </>
    )
  }
}

export default History;
import axios from 'axios';
import qs from 'qs';
import React, { Component } from 'react';
import moment from 'moment';

import {
  headerService$
} from "../../utils/rxjs-sharing";
import {
  Modal,
  notification,
  Button,
  Tooltip
} from 'antd';
import {
  LOADER,
  HOSPITALDEFAULTS,
  ISALLOWEDITSOAP,
  PATIENT_DATA,
  APPLY_ORDERSET,
  DRUG,
  SEARCH_DRUGS,
  SEARCH_DRUGS_FILTERED,
  PRESCRIPTION_DATA,
  PRESCRIPTION_STORE,
  DRUGS_CONSTANT,
  DELETE_MEDICINE,
  DRUG_FAVOURITE,
  BMI_CALCULATE,
  BSA_CALCULATE,
  ASSESSMENT_API,
  LAST_VITAL_ADDED_DATA,
  SAVE_TARGET_BLOOD_SUGAR,
  PATIENT_INFO,
  MEDICINES_GETALL_CURRENT_MEDICINES,
  MEDICINES_GETALL,
  CURRENT_MEDICINES_STOP,
  USERTYPES,
  DELETE_All_MEDICINE,
  CIMS_INTERACTION_EXISTS,
  CIMSINTERACTION,
  CIMSALLOW,
  ALLERGIES_GETALL,
  INFORMED_BY,
} from "../../utils/constant";

import LS_SERVICE from '../../utils/localStorage';
import SoapFlow from '../../utils/soap';
import MedicineForm from "../../components/drugs/medicine-form";
import OrdersetList from "../../components/orderset/orderset-list";
import MedicineTable from "../../components/drugs/medicine-table";
import SubHeaderMain from '../../components/subheader/subheadermain';
class PlanMedicineComponentSS extends Component {

  constructor(props) {
    super(props);
    this.state = {
      allowEdit: true,
      isPracticing: false,
      isPracticingModal: false,
      deleteConfirmModal: false,
      polyPharmacyModal: false,
      polyPharmacyFavModal: false,
      isModalInteractionVisible: false,
      selectedDrug: null,
      cimsallow: typeof LS_SERVICE.get('cimsEnabled') != 'undefined' && LS_SERVICE.get('cimsEnabled') === 'Y' ? true : false,
      drug_id_Interaction_Modalurl:[],
      referenceType:0,
      patientId: LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
      referenceId: LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      StoreId: LS_SERVICE.get("staff_id") != undefined ? LS_SERVICE.get("staff_id") : 0,
      FacilityId: LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
      enterpriseId: LS_SERVICE.get("ENTERPRISE_ID") != undefined ? LS_SERVICE.get("ENTERPRISE_ID") : 0,
      showtapper: true,
      current_clicked: 0,

      selectedOrderSet: [],
      selectedConfirmedOrderSetData: [],
      planFavDrugsModal: false,
      drugsData: [],
      addedDrugsList: [],
      diabaticPatient: false,
      target_blood_sugar_max: null,
      target_blood_sugar_min: null,
      medicine_success: '',
      current_medicines: [],
      presMedicine: [],
      showDupMed: [],
      currentMedsLists: [],
      duplicateMedModel: false,
      polyPharmacyWarningShown: false,
      eventFav: {},
      favMedData: {},
      constants: {},
      favourite: {},
      favourite_list_all: {},
      dataLoaded: false,
      shownPolymodelFav: false,
      diableConfrimButton: true,
      isFav:false,
      drugData : {},
      showActiveAllergyModal: false,
      getAllergiesList: [],
      allergyDetails: [],
      gynaeData: props.obsGyaneData || null,
      patient: {
        hospital_id   : LS_SERVICE.get("FACILITY_ID") != undefined ? LS_SERVICE.get("FACILITY_ID") : 0,
        patient_id    : LS_SERVICE.get("call_patient_id") != undefined ? LS_SERVICE.get("call_patient_id") : 0,
        qms_token_id  : LS_SERVICE.get("call_patient_qms_token_id") != undefined ? LS_SERVICE.get("call_patient_qms_token_id") : 0,
      },
      heightbmi: '',
      weightbmi: '',
      bmi: '',
      bsa: ''
    };
    this.medFormRef = React.createRef();
    this.checkCimsInteraction    = false;
    this.checkCimsInteractionfav = false;
    this.addFavMed               = false;
    this.handleDeleteAll = this.handleDeleteAll.bind(this)
  }

  async componentDidMount() {
    axios.defaults.headers.common["Authorization"] = LS_SERVICE.get("token");
    this.setState({
      isDataFetching: true,
    });
    const { patient } = this.state;
    const hospital_id =LS_SERVICE.get('call_patient_detail')?.hospital_id;
    const patient_id = patient.patient_id;
    const reference_id = patient.qms_token_id;
    const isPracticingHospitalId = LS_SERVICE.get("practicing")?.hospital_id;
    await this.loadData();
    if (+isPracticingHospitalId === +hospital_id) {
      this.setState({
        isPracticing: true,
      });
    }
    if (this.state.patient) {
      await this.setState({
        patientData: this.state.patient,
      });
    } else {
      await this.getPatientData(reference_id);
    }

    let hospitalConfigData = LS_SERVICE.get("hospitalConfigData") ?? [];
    hospitalConfigData = hospitalConfigData.find((item) => item.hospital_id == hospital_id);

    let allowEdit = hospitalConfigData
      ?
      ISALLOWEDITSOAP(
        hospitalConfigData.pres_modification,
        hospitalConfigData.allow_hours,
        LS_SERVICE.get("appointmentCompletedTime")[reference_id],
        "button"
      )
      :
      true
      ;
    this.setState({ hospital_id, patient_id, reference_id, patient, allowEdit }, async () => {
      if (LS_SERVICE.has("hospitalConfigData")) {
        const hospitalConfigData = LS_SERVICE.get("hospitalConfigData");
        let hospitalConfigObj = hospitalConfigData.find((obj) => obj.hospital_id == hospital_id);
        headerService$.soapConfig({ soapConfig: hospitalConfigObj?.opd_layout || 'single' });
        this.setSoapFlow();
        this.handleInitialData();
      }
    });
    this.loadAllergiesData();
    await this.setState({
      dataLoaded: true
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.showMedicineModal && !prevState.showMedicineModal) {
      // Modal just opened, so load the allergies data.
      this.loadAllergiesData();
    }
  }

  async setSoapFlow() {
    const { hospital_id } = this.state;
    SoapFlow.hospital(hospital_id)
  }
  

  polyPharmacyWarningShown = () => {
    this.setState({
      polyPharmacyWarningShown: true,
    })
  }

  clearEdit = () => {
    this.setState({
      editData: null,
      selectedDrug: null,
      editIndex: -1,
    });
  };

  showPolyModal = () => {
    this.setState({ polyPharmacyModal: true });
  };


  handlePolyOk = () => {
    this.setState({ polyPharmacyModal: false, polyPharmacyWarningShown: true }, async () => {
      var selected_order_set = []
      for (var data of this.state.selectedOrderSet) {
        await axios.get(APPLY_ORDERSET({
          orderset_id: data.orderSetId,
          patient_id: this.state.patient?.patient_id,
          reference_type: LS_SERVICE.get('reference_type'),
          reference_id: this.state.patient.qms_token_id
        }))
        selected_order_set.push(data)
      }
      await this.setState({
        showOrdersetModal: false,
        selectedConfirmedOrderSetData: this.state.selectedOrderSet,
        selectedOrderSet: this.state.selectedOrderSet
      })

      this.props.updateInstructionFromParent();

      this.loadData().then(success2 => {
        this.handleInitialData()
      })
    });
  };

  handlePolyCancel = (e) => {
    this.setState({ polyPharmacyModal: false, polyPharmacyWarningShown: true });
  };

  handlePolyFavCancel = (e) => {
    this.setState({ polyPharmacyFavModal: false });
  };

  showPolyModalForfav = (e) => {
    this.setState({ polyPharmacyFavModal: true });
  };

  handlePolyFavOk = async (e) => {
    const { eventFav, favMedData } = this.state
    await this.setState({ polyPharmacyFavModal: false, shownPolymodelFav: true });
    eventFav.target.checked = true;
    this.selectFavDrug(eventFav, favMedData);
  }

  IsApplyFav = async (data) => {

    const { eventFav } = this.state;

    let arr_conacat         = "";
    arr_conacat             = data.id;
    let {referenceId,StoreId,FacilityId,enterpriseId,patientId} = this.state
    let referenceType       = 0;
    let careProtoColId      = 0;
    let drugIds             = arr_conacat;
    let orederSetId         = 0;
    let medicineScreen      = 'addFavMed';
    let addedAssessmentList = 0;

    if (this.state.cimsallow) {
    await axios.get(CIMS_INTERACTION_EXISTS({ drugIds, referenceType, referenceId, orederSetId, patientId, enterpriseId, FacilityId, StoreId, careProtoColId, medicineScreen, addedAssessmentList }))
      .then(success => {
        if (success?.data?.interaction) {
          this.checkCimsInteractionfav = true;
          this.setState({ drug_id_Interaction_Modalurl:drugIds,careProtoColId: careProtoColId, referenceType: referenceType, referenceId: referenceId, orederSetId: orederSetId, enterpriseId: enterpriseId, FacilityId: FacilityId, StoreId: StoreId, patientId: patientId,drugData:data, isModalInteractionVisibleFav: this.checkCimsInteractionfav });
        }
        else {
          this.checkCimsInteractionfav = false;
          this.addFavMed               = true;
          const {drugData}             = this.state;
          /* let e ={
            checkConditon:true,
            target:{checked:true}
          } */
          eventFav.checkConditon = true;
          eventFav.target = {checked:true};
          this.selectFavDrug(eventFav, data,'','cims');
        }
      })
      .catch(err => console.log(err))
          
    }else{
      this.checkCimsInteractionfav = false;
      this.addFavMed               = true;
      const {drugData}             = this.state;
      /* let e ={
        checkConditon:true,
        target:{checked:true}
      } */
      eventFav.checkConditon = true;
      eventFav.target = {checked:true};
      this.selectFavDrug(eventFav, data,'','cims');
    }
  }

  popSearchDrug = () => {
    let drugVal = this.state.isFav
    if(drugVal && drugVal.length > 0){
       this.addFavMed= this.state.isFav.pop();
       this.setState({ isFav: this.addFavMed })
    }
}

  handleInteractionCancelFav = async () => {
    let { favourite,current_clicked} = this.state
    let {medicines} = favourite;
    current_clicked = 0;
   
    this.setState({isModalInteractionVisibleFav: false,isFav: false,current_clicked:current_clicked}, () => { this.addFavMed = false})
   
  };

  handleInteractionOkFav = async () => {
    await this.setState({ isModalInteractionVisibleFav: false,isFav: true }, () => { this.addFavMed = this.state.isFav})
    const {drugData} = this.state
    let e ={
      checkConditon:true,
      target:{checked:true}
    }
    this.selectFavDrug(e,drugData,'','cims')
   
  };

  selectFavDrug = async (e, data, checkIndex =null,type= null) => {

    let isChecked =  e.target.checked;

    await this.setState({ eventFav: e });

    let addFavMed;
    if (e.target.checked == "true" || e.target.checked == true) {
      addFavMed = true;
    } else {
      addFavMed = false;
    }
    if(type==null){
      e.preventDefault();
    }

    if (isChecked && type == null) {
        this.IsApplyFav(data)
        return;
    } else {
      this.addFavMed = false;
    }

    this.setState({
      current_clicked: data.id
    });

    var drugsDataArray = this.state.drugsData.map(drug_data_obj => ({ drug_id: drug_data_obj.drug_id }));
    drugsDataArray.push({drug_id: data.id})
    var fav_d = this.state.favourite?.medicines;
    var fav_drugs = await fav_d?.map(function (x) {
      var result = drugsDataArray.filter(medicine => medicine.drug_id == x.id);
      if (result.length > 0) { x.is_selected = 'Y' }
      else { x.is_selected = 'N' }
      return x
    })

   
    var fav = this.state.favourite;
    fav.medicines = fav_drugs;
    await this.setState({
      favourite: fav,
      favourite_list_all: fav
    });
    
     
    if (this.state.current_medicines?.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.shownPolymodelFav) {
      this.showPolyModalForfav()
      this.setState({ favMedData: data })
      return;
    }
    this.setState({
      shownPolymodelFav: false
    })
    if (e?.checkConditon != false) {
      const { patient_id, qms_token_id } = this.state.patient;
      await axios.get(MEDICINES_GETALL_CURRENT_MEDICINES({ patient_id, reference_type: 0, reference_id: qms_token_id })).
        then(success => {
          let currentMedsList = success.data.data.details.current_medicines;
          this.setState({
            currentMedsLists: currentMedsList,
          })
        }).catch(error => {
          console.error(error, "error");
        });

      const { currentMedsLists } = this.state
      let dupMedicine = [];
      currentMedsLists.map((med, i) => {
        if (data.id == med.drug_id && med.prescriptionDrugActive == 'Y')
          dupMedicine.push(med)
      })
      if (dupMedicine.length > 0) {
        const ids = dupMedicine.map(o => o.drug_id)
        const filteredMed = dupMedicine.filter(({ drug_id }, index) => !ids.includes(drug_id, index + 1))
        this.setState({
          presMedicine: dupMedicine, showDupMed: filteredMed, duplicateMedModel: true, 
          favMedData: data,
          shownPolymodelFav: false
        })
        return;
      }
    }
    

      
    // if (this.addFavMed == true || this.state.isFav == true) {
      data.title = data.name;
      await this.setState({
        selectedDrug: data,
      })
      var drug_data_response = await axios.post(DRUG, qs.stringify({
        id: data.id,
        medicineType: data?.medicineType || 'F',
        store_id: LS_SERVICE.get("STORE_ID"),
        facility_id: LS_SERVICE.get("FACILITY_ID"),
        enterprise_id: LS_SERVICE.get("ENTERPRISE_ID")
      }));
      var drug_data = drug_data_response?.data?.data?.result;
      var quantity_text = null;
      var quantity = null;
      if (drug_data?.frequency == 3) {
        quantity = 1;
      } else {
        let total_repeats = drug_data?.drug_frequency?.[0]?.frequency_id != 'undefined' ? drug_data?.drug_frequency?.[0]?.frequency_id?.total_repeats : 1;
        var drug_duration_days = drug_data.drug_duration_time == "Weeks" ? 7 : (drug_data.drug_duration_time == 'Months' ? 30 : (drug_data.drug_duration_time == 'Years' ? 365 : 1));
        var drug_duration_days_from_duration = drug_duration_days*drug_data?.drug_duration || 1;

        var drug_frequency_days = drug_data?.drug_frequency?.[0]?.frequency_id != 'undefined' ? drug_data?.drug_frequency?.[0]?.frequency_id?.interval_per == "Weeks" ? 7 : (drug_data?.drug_frequency?.[0]?.frequency_id?.interval_per == 'Months' ? 30 : (drug_data?.drug_frequency?.[0]?.frequency_id?.interval_per == 'Years' ? 365 : 1)) : 1;

        let numberOfDays = Math.floor(drug_duration_days_from_duration / drug_frequency_days);

        quantity = Math.ceil(parseInt(numberOfDays) * 1 * total_repeats);
        
        // if (drug_data?.drug_frequency?.[0]?.frequency_id?.id === 15) {
        //   let numberOfDays = Math.ceil(drug_duration_days_from_duration / 2);
        //   quantity = Math.ceil(parseInt(numberOfDays) * 1 * total_repeats);
        // } else {
        //   let numberOfDays = Math.ceil(drug_duration_days / drug_duration_days_from_duration);
        //   quantity = Math.ceil(parseInt(numberOfDays) * 1 * total_repeats);
        // }
      }
      if (drug_data?.form_id?.fixed_quantity && drug_data?.form_id?.fixed_quantity != null) {
        quantity = drug_data?.form_id?.fixed_quantity;
        quantity_text = 'Unit';
      }
      var end_date = await this.updateEndDate(drug_data?.drug_duration, drug_data?.drug_duration_time, drug_data?.drug_frequency?.[0]?.frequency_id?.id, moment().format('YYYY-MM-DD'))
      var schedules_name = '';
      if (drug_data?.drug_frequency?.length > 0 && typeof drug_data?.drug_frequency?.[0]?.frequency_id != 'undefined') {
        var frequencyid = drug_data?.drug_frequency?.[0]?.frequency_id.id;
        let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === frequencyid)
        schedules_name = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)].schedule.map(function (obj) { return obj.description }).toString().replace(/,/g, '-');
      }
      var total_strips = '';
      // if (drug_data.form_id?.id == 33) {
        if (drug_data.factor1 && drug_data.factor2) {
          var final_factor = drug_data.factor2 / drug_data.factor1;
          var total_strips_decimal = quantity / final_factor;
          var total_strips_floor = Math.floor(total_strips_decimal);
          var total_strips;
          if (total_strips_decimal == total_strips_floor) {
            total_strips = total_strips_floor
          }
          else {
            total_strips = total_strips_floor + 1
          }
        }
      // }

      await this.updateMedicineData({
        id: null,
        i: -1,
        drug_generic: drug_data?.drug_generic,
        active: drug_data?.active ? drug_data?.active : "Y",
        drug_id: drug_data?.id,
        drug_form: drug_data?.form_id?.id,
        strength: drug_data?.drug_dosage?.[0]?.strength,
        dosage: 2,
        dosage_value: drug_data?.drug_dosage?.[0]?.in_every,
        total_strips: total_strips ? total_strips : null,
        factor1: drug_data.factor1,
        factor2: drug_data.factor2,
        route: drug_data?.drug_route?.[0]?.route_id,
        drug_frequency: drug_data?.frequency,
        daily_frequency: drug_data?.drug_frequency?.[0]?.frequency_id?.id,
        number_of_days: drug_data?.drug_duration,
        number_of_days_type: drug_data?.drug_duration_time,
        days_in_week_text: null,
        days_in_week: drug_data?.days_in_week?.split(',').map(element => Number(element)),
        quantity: quantity ? quantity : null,
        instruction: drug_data?.instruction,
        medicine_remarks: drug_data?.medicine_remarks,
        drug_name: drug_data?.name,
        dosage_form: 0,
        dosage_form_text: drug_data.drug_dosage?.[0]?.favourite_dosage_form_text != null ? drug_data.drug_dosage?.[0]?.favourite_dosage_form_text : typeof drug_data?.drug_dosage?.[0]?.uom_first == 'undefined' || Object.keys(drug_data?.drug_dosage?.[0]?.uom_first).length === 0 ? '' : drug_data.drug_dosage?.[0]?.uom_first?.short_description,
        drug_fixed_qty: drug_data?.form_id?.fixed_quantity,
        prescription_drug_id: -1,
        urgency: drug_data?.urgency,
        schedules: drug_data?.schedules?.split(',').map(element => Number(element)),
        schedule_name: schedules_name,
        addContinueTillReview: end_date && end_date != null ? "N" : 'Y',
        start_date: moment().format('YYYY-MM-DD'),
        end_date: end_date,
        fav_selected: true
      });
      // this.loadData().then(success2 => {
      //   this.handleInitialData()
      // })
      this.setState({
        shownPolymodelFav: false
      })
    //}
    this.setState({
      current_clicked: 0
    });
  }

  stopCurentMeds() {
    const { presMedicine, eventFav, favMedData } = this.state;
    let stopDrugIds = presMedicine.map((drugDetailsId) => { return drugDetailsId.prescriptionDrugId })
    let doctor_id = LS_SERVICE.get('staff_id');
    let params = {
      stopDrugIds: stopDrugIds,
    }
    let stopPrescriptionDrugId = null;
    axios.put(CURRENT_MEDICINES_STOP({ stopPrescriptionDrugId, doctor_id }), params).then(res => {
      this.setState({ duplicateMedModel: false, shownPolymodelFav: true })
      eventFav.target.checked = true;
      eventFav.checkConditon = false;
      this.selectFavDrug(eventFav, favMedData);
      notification.success({
        message: 'Drug stopped successfully',
        placement: 'topRight'
      });
    }).catch(
      err => {
        notification.error({
          message: 'failed to stop medicine',
          placement: 'topRight'
        });
        console.log(err);
      }
    );
  }
  updateEndDate = (drug_number_of_days, day_interval, drug_daily_frequency, drug_start_date) => {
    drug_number_of_days = drug_number_of_days;
    drug_daily_frequency = drug_daily_frequency;
    var end_date = null;
    if (drug_number_of_days && drug_daily_frequency && drug_start_date) {
      var number_of_days = parseInt(drug_number_of_days);
      var start_date = drug_start_date;
      let frequencyObjKey = Object.keys(this.state.constants.dailyFrequency).find(key => this.state.constants.dailyFrequency[key].id === drug_daily_frequency)
      frequencyObjKey = this.state.constants.dailyFrequency[parseInt(frequencyObjKey)]
      // var day_interval = frequencyObjKey.interval;
      var day_interval = day_interval && day_interval != null ? day_interval.toLowerCase() : '';
      day_interval = day_interval == "weeks" ? 7 : (day_interval == 'months' ? 30 : (day_interval == 'years' ? 365 : 1));
      var times_correction = frequencyObjKey.total_times;
      var dosage_per_day = frequencyObjKey.total_repeats;
      if (typeof number_of_days != 'undefined' && !isNaN(number_of_days) && typeof start_date != 'undefined' && start_date != '' && times_correction != 0) {
        if (day_interval != 1)
          // number_of_days = Math.round(number_of_days / dosage_per_day);
          // number_of_days = number_of_days * day_interval * times_correction;
          number_of_days = number_of_days * day_interval;
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
      }
      if (times_correction == 0) {
        end_date = moment(start_date, 'YYYY-MM-DD').add(number_of_days - 1, 'days').format('YYYY-MM-DD');
      }
    }
    else if (drug_start_date == null) {
      end_date = null;
    }
    return end_date;
  }

  onChangeTargetBloodSugar = (value) => {
    this.setState({
      [value.target.name]: parseInt(value.target.value)
    }, () => {
      if (this.state.target_blood_sugar_min && this.state.target_blood_sugar_max) {
        if (this.state.target_blood_sugar_min < this.state.target_blood_sugar_max) {
          this.setState({
            target_blood_sugar_error_message: ""
          })
          var PARAMS = {
            patient_id: this.state.patient.patient_id,
            target_blood_sugar_min: this.state.target_blood_sugar_min,
            target_blood_sugar_max: this.state.target_blood_sugar_max
          }
          axios.post(SAVE_TARGET_BLOOD_SUGAR, PARAMS);
        }
        else {
          this.setState({
            target_blood_sugar_error_message: "From value must be lesser than To value"
          })
        }
      }
      else {
        this.setState({
          target_blood_sugar_error_message: ""
        })
      }
    })
  }

  IsApplyOrdersets = async (e) => {
    let arr = [];
    let arr_conacat = "";
    let selectedData = this.state.selectedOrderSet;
    selectedData.map(obj => {
      arr.push(obj.orderSetId);
    })
    arr_conacat = arr.join('-')
   
    let referenceId         = this.state.referenceId
    let StoreId             = this.state.StoreId
    let FacilityId          = this.state.FacilityId
    let enterpriseId        = this.state.enterpriseId
    let patientId           = this.state.patientId
    let referenceType       = 0;
    let orederSetId         = arr_conacat || 0;
    let drugIds             = 0;
    let careProtoColId      = 0;
    let medicineScreen      = 'medicineform';
    let addedAssessmentList = 0;
    let showInteractionModal = false;
    if(this.state.cimsallow){
      axios.get(CIMS_INTERACTION_EXISTS({ drugIds,referenceType,referenceId,orederSetId,patientId,enterpriseId,FacilityId,StoreId,careProtoColId,medicineScreen,addedAssessmentList }))
            .then(success => {
              if (success.data.interaction) {
                showInteractionModal = true;
                this.setState({ drug_id_Interaction_Modalurl: drugIds,referenceType:referenceType,referenceId:referenceId,orederSetId:orederSetId,enterpriseId:enterpriseId,FacilityId:FacilityId,StoreId:StoreId,patientId:patientId,isModalInteractionVisible: showInteractionModal});
              }
              else{
                this.ApplyOrdersets(e);
              }
            })
            .catch(err => console.log(err))
    }else{
      this.ApplyOrdersets(e);
    }
  }

  ApplyOrdersets = async (e) => {
    if (e !== null) e.preventDefault();
    if (this.state.current_medicines?.length > HOSPITALDEFAULTS.maximum_medicines_allowed && !this.state.polyPharmacyWarningShown) {
      await axios.get(MEDICINES_GETALL({ patient_id: this.state.patient?.patient_id }))
      .then(response => {
        this.setState({
          current_medicines: response?.data?.data?.details?.current_medicines || []
        })
      })
      this.showPolyModal()
    }
    else {
      var selected_order_set = []
      for (var data of this.state.selectedOrderSet) {
        await axios.get(APPLY_ORDERSET({
          orderset_id: data.orderSetId,
          patient_id: this.state.patient?.patient_id,
          reference_type: LS_SERVICE.get('reference_type'),
          reference_id: this.state.patient.qms_token_id
        }))
        selected_order_set.push(data)
      }
      await this.setState({
        showOrdersetModal: false,
        selectedConfirmedOrderSetData: this.state.selectedOrderSet,
        selectedOrderSet: this.state.selectedOrderSet
      })

    this.props.updateInstructionFromParent();

      this.loadData().then(success2 => {
        this.handleInitialData()
      })
    }
  };

  markDrugFavorite = (data) => {
    axios.post(DRUG_FAVOURITE, qs.stringify({
      drug_id: data.id,
      fav_drug: [{
        drug_id: data.id,
        drug_name: data.name,
        drug_form: '',
        dosage_form: '',
        strength: '',
        dosage: '',
        route: '',
        drug_frequency: '',
        urgency: '',
        daily_frequency: '',
        fav: 0,
        number_of_days: '',
        number_of_days_type: ''
      }],
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null
    })).then(success => {
      let drug_params = {search: ''};
      if(LS_SERVICE.get("user_type") == USERTYPES.admin){
        drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
      } else {
        drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
      }
      drug_params.store_id = LS_SERVICE.get("STORE_ID");
      drug_params.facility_id = LS_SERVICE.get("FACILITY_ID");
      drug_params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
      axios.post(SEARCH_DRUGS_FILTERED, qs.stringify(drug_params)).then(success => {
      LS_SERVICE.set('favouriteMedicines', success.data.medicine_list);
        this.loadData().then(success2 => {
          this.handleInitialData()
        })
      })
    }).catch(err => {
      console.log(err)
    })
  }

  loadFavMedicineData = ()=>{
    this.loadData().then(success2 => {
      this.handleInitialData();
    })
  }
  
  getPatientData = (_) => {
    const { patient } = this.state;
    const patient_id = patient.patient_id;
    axios.get(PATIENT_DATA({ patient_id }))
      .then((success) => {
        const patient = {
          ...success.data.data.results,
          qms_token_id: success.data.data.results.qmsToken ? success.data.data.results.qmsToken.qms_token_id : null,
        };

        // this.setState({ patient });
      })
      .catch((err) => console.log(err));
  };

  handlePracticeModal = data => {
    this.handleModalPopup(null, data);
  }

  /* async componentWillReceiveProps(){
    await this.handleInitialData();
  } */

  async handleDeleteAll(props) {
    var PARAMS = {
      all_prescription_id: props,
    }
    await axios.post(DELETE_All_MEDICINE, PARAMS)
      .then((success) => {
        this.handleInitialData();
        this.setState({
          spin_loading: false
        });
      })
      .catch((err) => console.log(err));
  }

  handleModalPopup = (e, popupName, data = null, actionType = "", objId = "", showtapper = true) => {
    if (e !== null) e.preventDefault();
    this.setState(
      {
        [popupName]: !this.state[popupName],
        showtapper:showtapper
      },
      () => {
        const { viewDocumentModal } = this.state;
        if (popupName == 'showMedicineModal' && this.state[popupName] == false) {
          this.setState({
            selectedDrug: null,
          })
        }
        this.setState(
          {
            actionText: "Add",
            editData: null,
            editIndex: -1,
          },
          () => {
            const { deleteConfirmModal, showMedicineModal } = this.state;

            if (showMedicineModal) { 

              let array_data = [];
              let array_ids = [];
              if (this.state.drugsData?.length > 0) {
                for (let i = 0; i < this.state.drugsData.length; i++) {
                  if (array_ids.includes(parseInt(i)) == false) {
                    let id1 = this.state.drugsData[i].drug_id;
                    array_data.push(this.state.drugsData[i]);

                    for (let j = i + 1; j < this.state.drugsData.length; j++) {
                      let id2 = this.state.drugsData[j].drug_id;
                      if (id1 == id2) {
                        array_data.push(this.state.drugsData[j]);
                        array_ids.push(parseInt(j));
                      }
                    }
                  }
                }
              }
              let finaldata = array_data;

              //let finaldata = [...this.state.drugsData]?.sort((a, b) => a.drug_id - b.drug_id); 
              if (actionType === "editMedicine") {
                this.setState({
                  actionText: "Edit",
                  editData: finaldata[objId],
                  editIndex: objId,
                });
                this.handleAddedMeds()
              }
            }

            if (deleteConfirmModal) {
              // For Medicines
              if (actionType === "deleteMedicine") {
                this.setState({ deleteActionType: actionType, deleteObjId: objId, deleteMessage: "Are you sure you want to delete medicine?" });
              }
            }
          }
        );
      }
    );
  };

  handleOrderSetPopup = async (e, type) => {
    if (e !== null) e.preventDefault();
    await this.setState({
      showOrdersetModal: type == 'show' ? true : false,
    })
    if (type == 'hide') {
      await this.setState({
        selectedOrderSet: this.state.selectedConfirmedOrderSetData,
        selectedConfirmedOrderSetData: this.state.selectedConfirmedOrderSetData
      })
    }
    this.loadData().then(success2 => {
      this.handleInitialData()
    })
  }

  handleCurrentMedicines = async () => {
    this.medFormRef.current.handleActiveMedsList()
  }

  handleOnItemDelete = async (e, actionType, objId) => {
    e.preventDefault();
    var stateVariable = null;
    var stateAddedVariable = null;
    var propKey = null;
    var deleteUrl = null;

    // For Drug
    if (actionType === "deleteMedicine") {
      stateVariable = "drugsData";
      stateAddedVariable = "addedDrugsList";
      propKey = "drug_id";
      deleteUrl = DELETE_MEDICINE;
    }

    let dataArray = this.state[stateVariable];
    var deleteObj = dataArray[objId];
    var deleteId = deleteObj.id;
    if (actionType === "deleteMedicine")
    { 
      let array_data = [];
      let array_ids = [];
      if (dataArray?.length > 0) {
        for (let i = 0; i < dataArray.length; i++) {
          if (array_ids.includes(parseInt(i)) == false) {
            let id1 = dataArray[i].drug_id;
            array_data.push(dataArray[i]);

            for (let j = i + 1; j < dataArray.length; j++) {
              let id2 = dataArray[j].drug_id;
              if (id1 == id2) {
                array_data.push(dataArray[j]);
                array_ids.push(parseInt(j));
              }
            }
          }
        }
      }
      let finaldata = [...array_data];
      //let finaldata = [...dataArray]?.sort((a, b) => a.drug_id - b.drug_id); 

       deleteObj = finaldata[objId];
       deleteId = deleteObj.id;
       deleteId = deleteObj.prescription_drug_id;
    }

    this.setState({
      spin_loading: true,
    });
    dataArray.splice(objId, 1);
    let addedArray = [];
    dataArray.map((v, k) => {
      addedArray.push(v[propKey]);
    });
    this.setState({
      deleteConfirmModal: false,
      [stateVariable]: dataArray,
      [stateAddedVariable]: addedArray,
    });
    var drugsDataArray = dataArray.map(drug_data_obj => ({ drug_id: drug_data_obj.drug_id }));
    var fav_d = this.state.favourite?.medicines;
    var fav_drugs = await fav_d?.map(function (x) {
      var result = drugsDataArray.filter(medicine => medicine.drug_id == x.id);
      if (result.length > 0) { x.is_selected = 'Y' }
      else { x.is_selected = 'N' }
      return x
    })
    var fav = this.state.favourite;
    fav.medicines = fav_drugs;
    await this.setState({
      favourite: fav,
      favourite_list_all: fav
    });

    axios.get(deleteUrl({ id: deleteId }))
      .then((success) => {
        this.handleInitialData();
        this.handleAddedMeds()
        this.setState({
          spin_loading: false
        });

      })
      .catch((err) => console.log(err));
  };

  updateMedicineData = async (data, showTaper) => {
    data.selected = true;
    let drugsData = this.state.drugsData;
    let addedDrugsList = [];
    var index = data.i;
    if (index == -1) index = drugsData == null ? 0 : drugsData.length;
    drugsData[index] = data;

    addedDrugsList = drugsData.map((v, k) => {
      return v.drug_id;
    });
    const PARAMS = {
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: this.state.patient.qms_token_id,
      patient_id: this.state.patient.patient_id,
      medicine_data: [data],
    };
    if (data?.prescription_drug_id > 0) {
      if (!showTaper) this.handleModalPopup(null, "showMedicineModal");
    }
    const medsFormPromise = await axios.post(PRESCRIPTION_STORE, qs.stringify(PARAMS));
    this.setState({ editData: null, editIndex: -1, drugsData, addedDrugsList }, ()=>{
      this.handleInitialData()
    });
    if (medsFormPromise.data) {
      await this.handleAddedMeds();
      if (data?.prescription_drug_id > 0) {
        // if (!showTaper) this.handleModalPopup(null, "showMedicineModal");
        notification.success({
          message: medsFormPromise.data.message,
          placement: "topRight",
        });
      }
      else {
        if (!data?.fav_selected) {
          this.setState({
            medicine_success: data.drug_name + ' added successfully'
          })
          setTimeout(async () => {
            this.setState({
              medicine_success: ''
            })
          }, 7000);
        }
      }
    }
  };

  selectOrderSet = async (data, checked) => {
    if (data.diableConfrimButton.length > 0) {
      this.setState({
        diableConfrimButton: false
      })

    } else {
      this.setState({
        diableConfrimButton: true
      })
    }

    var selectedOrderSet = this.state.selectedOrderSet

    if (checked == true) {
      data.is_selected = 'Y';
      selectedOrderSet.push(data)

    } else {
      selectedOrderSet = selectedOrderSet.filter((item) => item.orderSetId !== data.orderSetId);
    }
    await this.setState({
      selectedOrderSet: selectedOrderSet
    })
    
  };

  loadData = async () => {
    let { constants, favourite } = this.state;

    // Drug Constants
    const drugConstants = await axios.get(DRUGS_CONSTANT);
    constants = drugConstants.data.data;

    // Fav Drugs
    let drug_params = {search: ''};
    if(LS_SERVICE.get("user_type") == USERTYPES.admin){
      drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('admin_hospital_id') : null
    } else {
      drug_params.hospital_id = LS_SERVICE.get('slot_hospital_master') == 'Y' ? LS_SERVICE.get('slot_hospital_id') : null;
    }
    drug_params.store_id = LS_SERVICE.get("STORE_ID");
    drug_params.facility_id = LS_SERVICE.get("FACILITY_ID");
    drug_params.enterprise_id = LS_SERVICE.get("ENTERPRISE_ID");
    var drugsFavourite = [];
    if(!LS_SERVICE.get("favouriteMedicines")){
      var interval = setInterval(() => {
        if(LS_SERVICE.get("favouriteMedicines")){
          drugsFavourite = LS_SERVICE.get("favouriteMedicines") || [];
          favourite.medicines = drugsFavourite;
          this.setState({
            constants: constants,
            favourite: favourite,
          });
          clearInterval(interval);
        }
        else{
          console.log('not yet');
        }
      }, 500);
      this.setState({
        constants: constants,
        favourite: favourite,
      });
    }
    else{
      drugsFavourite = LS_SERVICE.get("favouriteMedicines") || [];
      favourite.medicines = drugsFavourite;
      this.setState({
        constants: constants,
        favourite: favourite,
      });
    }
    await this.setState({
      constants: constants,
      favourite: favourite,
    });
  };

  handleInitialData = async () => {
    const { patient_id, qms_token_id } = this.state.patient;
    const planDrugAdvPromise = await axios.get(PRESCRIPTION_DATA({
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' && LS_SERVICE.get('slot_hospital_id') ? LS_SERVICE.get('slot_hospital_id') : null,
      patient_id,
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      store_id: LS_SERVICE.get("STORE_ID"),
      facility_id: LS_SERVICE.get("FACILITY_ID"),
      enterprise_id: LS_SERVICE.get("ENTERPRISE_ID")
    }))
    if (planDrugAdvPromise.data.status === 'success') {
      const { duplicateOrderDrugs, details } = planDrugAdvPromise.data.data;
      let addedDrugsList = [];
      addedDrugsList = details.map((v, k) => { return v.drug_id });
      await this.setState({
        drugAdvisedDuplicateOrders: duplicateOrderDrugs,
        drugsData: details,
        addedDrugsList
      })
      if (details.length > 0) {
        this.props.viewMedicineData();
        this.props.getDrugsList(addedDrugsList);
        this.props.viewlengthMedicineData(details.length);
      }
      else{
        this.props.viewlengthMedicineData(0);
      }
      this.checkMedicineDataEmpty()
    }
    var drugs = this.state.drugsData;
    await this.state.favourite?.medicines?.map(function (x) {
      var result = drugs.filter(medicine => medicine.drug_id == x.id);
      if (result.length > 0) { x.is_selected = 'Y' }
      else { x.is_selected = 'N' }
      return x
    })
    var fav = this.state.favourite;
    fav.medicines = this.state.favourite?.medicines;
    await this.setState({
      favourite: fav
    });

    await this.setState({
      favourite_list_all: this.state.favourite
    })

    await axios.get(LAST_VITAL_ADDED_DATA({ patient_id }))
      .then(success => {
        this.setState({
          heightbmi: success.data.data.results.height,
          weightbmi: success.data.data.results.weight
        }, () => {
          if (success.data.data.results.height != '' && success.data.data.results.weight != '') {
            let bmi = BMI_CALCULATE(success.data.data.results.height, success.data.data.results.weight);
            let bsa = BSA_CALCULATE(success.data.data.results.height, success.data.data.results.weight);
            this.setState({
              bmi: bmi,
              bsa: bsa
            });
          }
        })
      })

    await axios.get(ASSESSMENT_API({ reference_type: LS_SERVICE.get('reference_type'), reference_id: qms_token_id }))
      .then(assessDataPromise => {
        if (assessDataPromise.data) {
          let { data } = assessDataPromise.data;
          if (data.activeDiagnosisDetails || data.activeDiagnosisDetails.length > 0) {
            data.activeDiagnosisDetails.map((data_active, i) => {
              if (data_active?.is_diabetic == 'Y') {
                this.setState({ diabaticPatient: true });
              }
            });
          }
        }
      });

    await axios.get(PATIENT_INFO({ patient_id: patient_id, reference_id: qms_token_id, reference_type: LS_SERVICE.get('reference_type') }))
      .then(response => {
        this.setState({
          target_blood_sugar_max: response?.data?.data?.results?.target_blood_sugar_max,
          target_blood_sugar_min: response?.data?.data?.results?.target_blood_sugar_min
        })
      })
    await axios.get(MEDICINES_GETALL({ patient_id: patient_id }))
      .then(response => {
        let currentMedsList = response?.data?.data?.details?.current_medicines;
        let tempArr = currentMedsList.map(function (v) { return v.drug_id });
        tempArr = tempArr.filter(function (item, pos) { return tempArr.indexOf(item) == pos; })
        this.setState({
          current_medicines: tempArr
        })
      })
  };

  handleAddedMeds = async () => {
    const { patient_id, qms_token_id } = this.state.patient;
    const planDrugAdvPromise = await axios.get(PRESCRIPTION_DATA({
      hospital_id: LS_SERVICE.get('slot_hospital_master') == 'Y' && LS_SERVICE.get('slot_hospital_id') ? LS_SERVICE.get('slot_hospital_id') : null,
      patient_id,
      reference_type: LS_SERVICE.get('reference_type'),
      reference_id: qms_token_id,
      store_id: LS_SERVICE.get("STORE_ID"),
      facility_id: LS_SERVICE.get("FACILITY_ID"),
      enterprise_id: LS_SERVICE.get("ENTERPRISE_ID")
    }))
    if (planDrugAdvPromise.data.status === 'success') {
      const { duplicateOrderDrugs, details } = planDrugAdvPromise.data.data;
      let addedDrugsList = [];
      addedDrugsList = details.map((v, k) => { return v.drug_id });
      await this.setState({
        drugAdvisedDuplicateOrders: duplicateOrderDrugs,
        drugsData: details,
        addedDrugsList
      })
      if(details.length > 0){
        this.props.viewMedicineData();
        this.props.getDrugsList(addedDrugsList);
      }
    }
    // var drugs = this.state.drugsData;
    // var fav_d = this.state.favourite?.medicines;
    // var fav_drugs = await fav_d?.map(function (x) {
    //   var result = drugs.filter(medicine => medicine.drug_id == x.id);
    //   if (result.length > 0) { x.is_selected = 'Y' }
    //   else { x.is_selected = 'N' }
    //   return x
    // })
    // var fav = this.state.favourite;
    // fav.medicines = fav_drugs;
    // await this.setState({
    //   favourite: fav,
    //   favourite_list_all: fav
    // });
  };

  handleCancel = () => {
    this.setState({ planFavDrugsModal: false });
    this.loadData().then(success2 => {
      this.handleInitialData()
    })
  }

  FavouriteListLoad = async (e) => {

    var searchTerm = e?.target?.value;

    let favourite_list_all = this.state.favourite;
    let filtered = favourite_list_all.medicines.filter(list => list.name.toString().toLowerCase().indexOf(searchTerm.toString().toLowerCase()) >= 0)

    await this.setState({
      favourite_list_all: { medicines: filtered }
    })
  }

  async handleMedPrecribedCancel() {
    await this.setState({
      duplicateMedModel: false,
    })
  }

  // printCompleteButton(checkMedData){
  //   this.props.printCompleteButtonDiabled(checkMedData);
  // }

  checkEmptyValue(val) {
    return typeof val == 'undefined' || val == null || val == '' ? true : false;
  }

  checkMedicineDataEmpty() {
    const { drugsData } = this.state;
    let checkMedData = false;
    // drugsData.map((data) => {
    //   if (data.medicineType == 'F') {
    //     if (this.checkEmptyValue(data.dosage_value)) { checkMedData = true };
    //     if (this.checkEmptyValue(data.route)) { checkMedData = true };
    //   }
    // })
    this.props.printCompleteButtonDiabled(checkMedData)
    // medicineDataEmptyDisableButton.init({
    //   medicineDataEmpty: checkMedData
    // })
  }

  handleInteractionOk = (e) => { 
    this.setState({ isModalInteractionVisible: false,  })
    this.ApplyOrdersets(e);
   };
  handleInteractionCancel = () => { this.setState({ isModalInteractionVisible: false },
   ); };

  loadAllergiesData = async () => {
    try {
      const { patient } = this.state;
      // fetch allergy list
      const response = await axios.get(ALLERGIES_GETALL({ patient_id: patient.patient_id }));
      if(response != null) {
      this.setState({
        getAllergiesList: response?.data?.data?.details?.allergies?.patientAllergies || [],
      });
    }
    } catch (error) {
      console.error("Error handling allergy history:", error);
    }
  };
  
  handleAllergyPopup = (e) => {
    e.preventDefault();
  
    // Combined all allergy data regardless of the type clicked
    const allergyDetails = this.state.getAllergiesList;
  
    // Set the combined data to state to show in the popup
    this.setState({
      showActiveAllergyModal: true,
      allergyDetails
    });
  };
  
  generateModalTitle = () => {
    // this.loadAllergiesData();
    const { actionText } = this.state;

    let title = actionText + " Medicine";

    return (
      <div>
        {title}
      </div>
    );
  }

  generateAllergyDetail = () => {
    const { getAllergiesList } = this.state;

    let links = [];

    if (getAllergiesList && getAllergiesList?.length > 0) {
      let allergyTitles = {
        drug: [],
        food: [],
        others: [],
      };

      getAllergiesList.forEach(allergy => {
        if (allergy.allergyType === 'drug') {
          allergyTitles.drug.push(allergy.allergy_name);
        } else if (allergy.allergyType === 'food') {
          allergyTitles.food.push(allergy.allergy_name);
        } else if (allergy.allergyType === 'others') {
          allergyTitles.others.push(allergy.allergy_name);
        }
      });

      if (allergyTitles.drug?.length > 0) {
        links.push(
          <div className="row">
            <div className='col-2'>
              <a className='text-secondary' key="drug" onClick={this.handleAllergyPopup}>
                <u>Drug Allergy</u>
              </a>
            </div>
            <div className='col'>
              {allergyTitles.drug.map((drug, index) => {
                let derugData = drug.toLowerCase();
                return (
                  <React.Fragment key={index}>
                    <span className='round-bullet-dot mr-2'></span>
                    <span className='text-capitalize mr-2'>{derugData}</span>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        );
      }

      if (allergyTitles.food.length > 0 || allergyTitles.others.length > 0) {
        links.push(
          <div className="row">
            <div className='col-2'>
              <a className='text-secondary' key="food" onClick={this.handleAllergyPopup}>
                <u>Food / Other Allergy</u>
              </a>
            </div>
            <div className='col'>
              {allergyTitles.food.map((food, index) => (
                <React.Fragment key={index}>
                  <span className='round-bullet-dot mr-2'></span>
                  <span className='text-capitalize mr-2'>{food}</span>
                </React.Fragment>
              ))}
              {allergyTitles.others.map((others, index) => (
                <React.Fragment key={index}>
                  <span className='round-bullet-dot mr-2'></span>
                  <span className='text-capitalize mr-2'>{others}</span>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }

      return (
        <div className="col-12 emrfrm px-0 mb-3">
          <div className='container-fluid px-0 emrfrm'>
            <div className='container-fluid mt-0 px-3 pt-2 color_rows'>
              {links}
            </div>
          </div>
        </div>
      );
    }
    else{
      return
    }
  }
  
  render() {
    const { isPracticing, dataLoaded ,favourite } = this.state;

    favourite?.medicines?.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });


    var basic_vitals = [];
    if (this.state.heightbmi && this.state.heightbmi != null) { basic_vitals.push('Height ' + this.state.heightbmi + ' cm') }
    if (this.state.weightbmi && this.state.weightbmi != null) { basic_vitals.push('Weight ' + this.state.weightbmi + ' kg') }
    if (this.state.bmi && this.state.bmi != null) { basic_vitals.push('BMI ' + this.state.bmi) }
    if (this.state.bsa && this.state.bsa != null) { basic_vitals.push('BSA ' + this.state.bsa) }
    var basic_vitals_string = basic_vitals.join(" | ");
    return (
      <>
        {
          dataLoaded == true
            ?
            <>
              <div className="card-body medicines_body p-1 emrfrm">
                <div className='container-fluid medicines  med-cont pb-2'>
                  <div className='row'>
                    <div className='col-xl-4 col-lg-5 mt-0'>
                      <div className='row'>
                        <div className='col-12'>
                          <span className='text-primary med-font'>{basic_vitals_string}</span>
                        </div>
                      </div>
                    </div>
                    {
                      this.state.diabaticPatient == true
                        ?
                        <>
                          <div className='col-xl-5 col-lg-3'>
                            <div className='row mt-0'>
                              <div className='col-6 text-right'>
                                <span className='text-primary med-font'>Target Blood Sugar(mg/dL)</span>
                                <div>
                                  <label style={{ fontSize: "14px", whiteSpace: "nowrap" }} className="text-secondary">
                                    {this.state.target_blood_sugar_error_message}
                                  </label>
                                </div>
                              </div>
                              <div className='col-3 pr-0'>
                                <div className="form-group hasdata mb-0">
                                  <input
                                    type="text"
                                    className="form-control drug-input mandatory-field onlynumbers border-right-plan"
                                    maxLength="4"
                                    name="target_blood_sugar_min"
                                    id="target_blood_sugar_min"
                                    onChange={(e) => { this.onChangeTargetBloodSugar(e) }}
                                    value={this.state?.target_blood_sugar_min ? this.state.target_blood_sugar_min : ""}
                                    defaultValue={this.state?.target_blood_sugar_min ? this.state.target_blood_sugar_min : ""}
                                  />
                                  <label htmlFor="target_blood_sugar_min">From</label>
                                </div>
                              </div>
                              <div className='col-3 pl-0'>
                                <div className="form-group hasdata mb-0">
                                  <input
                                    type="text"
                                    className="form-control drug-input mandatory-field onlynumbers border-left-plan"
                                    maxLength="4"
                                    name="target_blood_sugar_max"
                                    id="target_blood_sugar_max"
                                    onChange={(e) => { this.onChangeTargetBloodSugar(e) }}
                                    value={this.state?.target_blood_sugar_max ? this.state.target_blood_sugar_max : ""}
                                    defaultValue={this.state?.target_blood_sugar_max ? this.state.target_blood_sugar_max : ""}
                                  />
                                  <label htmlFor="To">To</label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                        :
                        null
                    }
                    {/* <div className='col-xl col-lg mt-0'>
                    <div className='row'>
                      <div className='col-12 text-right'>
                        <a 
                          class="btn btn-outline-secondary mr-2 px-2"
                          id="care_protocol"
                          href="#"
                          onClick={(e) => {
                            this.state.allowEdit && this.handleOrderSetPopup(e, 'show');
                          }}
                        >
                          ORDER SET
                        </a>
                        <a 
                          class="btn btn-outline-primary ml-2 px-2"
                          id="diagnosis"
                          href="#"
                          onClick={(e) => {
                            this.state.allowEdit && this.handleModalPopup(e, !isPracticing ? "isPracticingModal" : "showMedicineModal");
                          }}
                        >
                          + MEDICINES
                        </a>
                      </div>
                    </div>
                  </div> */}
                  </div>

                  {
                    this.state.favourite?.medicines?.length > 0
                      ?
                      <div className="card card-blue-card mt-1 mb-2">
                        <div className="card-body">
                          <div className='row '>
                            <div className='col-1'>
                              <div className='row'>
                                <div className='col-12'>
                                  <span className='fav-text'>Favourites</span>
                                </div>
                                {
                                  this.state.favourite?.medicines?.length > 20
                                    ?
                                    <div className='col-12'>
                                      <a className='text-secondary' onClick={() => { this.setState({ planFavDrugsModal: true }) }}><u>View All</u></a>
                                    </div>
                                    :
                                    null
                                }
                              </div>
                            </div>
                            <div className='col-11'>
                              <div className='row'>
                                <div className='col-12'>
                                  <ul className='list-unstyled d-flex flex-wrap'>
                                    {favourite?.medicines?.map((data, i) => {
                                      return (
                                        <>
                                          {
                                            i < 20
                                              ?
                                              <li className='pr-3 pb-2 col-3'>
                                                <div className="custom-control custom-checkbox custom-control-inline mr-1">
                                                  <input type="checkbox" className="custom-control-input" name="fav_list" checked={data.is_selected == 'Y' ? true : false} id={'fav_' + data.id} disabled={data.is_selected == 'Y' || this.state.current_clicked == data.id ? true : false} onChange={e => this.selectFavDrug(e, data, i)} />
                                                  <label className="custom-control-label" htmlFor={'fav_' + data.id}>
                                                    <Tooltip overlayInnerStyle={{ color: "#fff", backgroundColor: '#666', borderRadius: '6px' }} placement="top" title={data.name}>
                                                      <div style={{ overflow: 'hidden', maxWidth: '20ch', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: '0.95rem' }}>{data.name}</div>
                                                    </Tooltip>
                                                  </label>
                                                  <span className="pl-2">
                                                    <i className="icon_unique icon_star favourite align-star cursor-pointer" onClick={() => this.markDrugFavorite(data)}></i>
                                                  </span>
                                                </div>
                                              </li>
                                              :
                                              null
                                          }
                                        </>
                                      )
                                    })}
                                  </ul>

                                </div>
                              </div>

                            </div>
                          </div>

                        </div>
                      </div>
                      :
                      null
                  }

                  <MedicineTable
                    drugsData={this.state.drugsData}
                    viewType={"soap-view"}
                    showAction={true}
                    showSelectCheck={false}
                    constants={this.state.constants}
                    handlePopUp={this.handleModalPopup}
                    handleDeleteAll={this.handleDeleteAll}
                    isPracticing={isPracticing}
                    practiceModal={this.handlePracticeModal}
                    deleteAllMedicineOnce={true}
                    editGrid={true}
                    updateMedicineData={this.handleInitialData}
                    gynaeData={this.state.gynaeData}
                    patientData={this.state.patientData}
                  />
                </div>
              </div>
              <Modal
                title="Medicine Prescribed Alert"
                visible={this.state.duplicateMedModel}
                onCancel={(e) => this.handleMedPrecribedCancel()}
                footer={false}
                closable={false}
              >
                <div className="row mb-3">
                  {
                    this.state.showDupMed && this.state.showDupMed.length > 0 ?
                      <div className="col-12 text-primary"><span>
                        {this.state.showDupMed.map((drug, i) => {
                          if (this.state.showDupMed.length == i + 1) {
                            return drug.drug_name + "  "
                          } else {
                            return drug.drug_name + "  , "
                          }
                        }
                        )}
                      </span>
                        {this.state.showDupMed.length > 1 ?
                          <span className="font-weight-bold"> Medicines are already prescribed to the patient. Are you sure you want to stop the medication and prescribe again?</span>
                          :
                          <span className="font-weight-bold"> Medicines is already prescribed to the patient. Are you sure you want to stop the medication and prescribe again?</span>
                        }
                      </div>
                      : null
                  }

                </div>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleMedPrecribedCancel(e)}>No</button>
                  <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.stopCurentMeds(e)}>YES</button>
                </div>
              </Modal>
              <Modal
                title="Poly Pharmacy Alert"
                visible={this.state.polyPharmacyFavModal}
                onCancel={(e) => this.handlePolyFavCancel()}
                footer={false}
                closable={false}
              >
                <div className="row mb-3">
                  <div className="col-12">The patient is already on {this.state.current_medicines?.length} medications. Are you sure you want to prescribe more?</div>
                </div>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyFavCancel(e)}>No</button>
                  <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyFavOk(e)}>Yes</button>
                </div>
              </Modal>

              <Modal
                title="Poly Pharmacy Alert"
                visible={this.state.polyPharmacyModal}
                onCancel={(e) => this.handlePolyCancel()}
                footer={false}
                closable={false}
              >
                <div className="row mb-3">
                  <div className="col-12">The patient is already on {this.state.current_medicines?.length} medications. Are you sure you want to prescribe more?</div>
                </div>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handlePolyCancel(e)}>No</button>
                  <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handlePolyOk(e)}>Yes</button>
                </div>
              </Modal>
              {/* Active Allergy List */}
              <Modal
                title={"Active Allergy"}
                visible={this.state.showActiveAllergyModal}
                onCancel={() => this.setState({ showActiveAllergyModal: false })}
                footer={false}
                width={'80%'}
                style={{ zIndex: 1051 }}
                destroyOnClose={true}
                >
                <div className="bg-white overflow-auto">
                  <table className="table table-bordered" >
                    <tbody>
                      {this.state.allergyDetails?.length > 0 ?
                        <tr>
                          <th width="200">Allergen</th>
                          <th width="200">Reaction</th>
                          <th width="120">Severity</th>
                          <th width="200">Source</th>
                        </tr>
                        : ''}
                      {this.state.allergyDetails.length > 0 ? this.state.allergyDetails?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((allergy, index) => (
                        <tr>
                          <td width="200">{allergy.allergy_name}</td>
                          <td width="200">{allergy.reaction}</td>
                          {allergy.intensity == null ? <td width="120"></td>
                            : allergy.intensity === 0 ? <td width="120"><Tooltip placement="top" title='Low'><i className="icon-severity-low" /></Tooltip> </td>
                              : allergy.intensity === 1 ? <td width="120"><Tooltip placement="top" title='Moderate'><i className="icon-severity-medium" /></Tooltip> </td>
                                : <td width="120"><Tooltip placement="top" title='High'><i className="icon-severity-high" /> </Tooltip></td>
                          }
                          <td width="200">{INFORMED_BY[allergy.informedBy]}</td>
                        </tr>
                      )) : <tr><td colSpan="5">No results found</td></tr>}
                    </tbody>
                  </table>
                </div>
              </Modal>
              {/* Favorite Drugs Complete List */}
              <Modal
                destroyOnClose={true}
                title='Favourite Drugs'
                visible={this.state.planFavDrugsModal}
                onCancel={() => this.handleCancel()}
                footer={false}
                width="1000px"
                className="tests_modal"
              >
                <div className='row fav_modal emrfrm'>
                  <div className='col-12'>
                    <div className="form-group">
                      <label htmlFor="search-template">
                        Search Favourites
                      </label>
                      <input
                        autoComplete="off"
                        type="text"
                        className="form-control"
                        id="search-template"
                        placeholder="Search Favourites"
                        onChange={this.FavouriteListLoad}
                      />
                    </div>
                    <table className="table table-bordered mt-3" id="diagnosis-list">
                      <thead>
                        <tr>
                          <td width="10%"></td>
                          <td width="75%">Drug NAME</td>
                          <td width="15%">FAVOURITE</td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.favourite_list_all?.medicines?.map((data, i) => {
                          return (<tr>
                            <td className='text-center'>
                              <div className="custom-control custom-checkbox custom-control-inline mr-0">
                                <input type="checkbox" className="custom-control-input" name="fav_check" checked={data.is_selected == 'Y' ? true : false} id={'fav_' + data.id} disabled={data.is_selected == 'Y' || this.state.current_clicked == data.id ? true : false} onChange={e => this.selectFavDrug(e, data, i)} />
                                <label className="custom-control-label" htmlFor={'fav_' + data.id}></label>
                              </div>
                            </td>
                            <td>{data.name}</td>
                            <td className='text-center'>
                              <span className="">
                                <i className="icon_unique icon_star favourite align-star cursor-pointer" onClick={() => this.markDrugFavorite(data)}></i>
                              </span>
                            </td>
                          </tr>)
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Modal>

              {/* Add New Drug  */}
              {this.state.showMedicineModal ? (
                <Modal
                  title={this.generateModalTitle()}
                  visible={this.state.showMedicineModal}
                  onCancel={(e) =>{ this.handleModalPopup(e, "showMedicineModal");  this.setState({addedDrugsList: []})}}
                  footer={this.state.medicine_success != '' ?
                    <div className="text-center mt-1">
                      <span style={{ color: '#548f3a' }}>{this.state.medicine_success}</span>
                    </div> : false
                  }
                  width="1200px"
                  className='medicine_pop_padding'
                >
                  <div className='row'>
                    <div className='col-12 px-4'>
                    <SubHeaderMain />
                    {this.generateAllergyDetail()}
                    </div>
                  </div>
                  {this.state.showMedicineModal ? (
                    <MedicineForm
                      formType={"soap-modal"}
                      actionText={this.state.actionText}
                      updateData={this.updateMedicineData}
                      autofocus={false}
                      /* selectedDrug={this.state.selectedDrug} */
                      editData={this.state.editData}
                      editIndex={this.state.editIndex}
                      addedDrugsList={this.state.addedDrugsList}
                      constants={this.state.constants}
                      patient={this.state.patient}
                      clearEdit={this.clearEdit}
                      polyPharmacyWarningShown={this.state.polyPharmacyWarningShown}
                      updatepolyPharmacyWarningShown={this.polyPharmacyWarningShown}
                      showtapper ={this.state.showtapper}
                      loadFavMedicine ={true}
                      loadFavMedicineData ={this.loadFavMedicineData}
                      heightbmi={this.state.heightbmi}
                      weightbmi={this.state.weightbmi}
                      bmi={this.state.bmi}
                      bsa={this.state.bsa}
                    />
                  ) : null}
                </Modal>
              ) : null}

              {/* Delete added test */}
              <Modal
                title={false}
                visible={this.state.deleteConfirmModal}
                onCancel={(e) => this.handleModalPopup(e, "deleteConfirmModal")}
                footer={false}
                closable={false}
              >
                <div className="row mb-3">
                  <h6 className="col-12 text-center">{this.state.deleteMessage}</h6>
                </div>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2" onClick={(e) => this.handleModalPopup(e, "deleteConfirmModal")}>
                    CANCEL
                  </button>
                  <button
                    ref={this.deleteButton}
                    type="button"
                    className="btn btn-primary px-5"
                    onClick={(e) => this.handleOnItemDelete(e, this.state.deleteActionType, this.state.deleteObjId)}
                  >
                    DELETE
                  </button>
                </div>
              </Modal>

              <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
                title="Drug Interaction"
                visible={this.state.isModalInteractionVisible}
                onOk={() => this.handleInteractionOk()}
                onCancel={() => this.handleInteractionCancel()}
                footer={false}
                style={{width:1000}}
                zIndex={7776}
                >
                
                <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMSINTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${'medicineform'}&addedAssessmentList=${0}`}></iframe>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancel(e)}>DON’T PROCEED</button>
                  <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOk(e)}>PROCEED TO ORDER</button>
                </div>
              </Modal>


              <Modal className="myDrugsInteraction_modal" id="myDrugsInteraction"
                title="Drug Interaction"
                visible={this.state.isModalInteractionVisibleFav}
                onOk={() => this.handleInteractionOkFav()}
                onCancel={() => this.handleInteractionCancelFav()}
                footer={false}
                style={{width:1000}}
                zIndex={7776}
                >
                <iframe id="drug_interaction_widget_iframe2" title="interaction" className="cimsiframe" src={`${CIMSINTERACTION}?drugIds=${this.state.drug_id_Interaction_Modalurl}&referenceId=${this.state.referenceId}&referenceType=${this.state.referenceType}&enterpriseId=${this.state.enterpriseId}&FacilityId=${this.state.FacilityId}&StoreId=${this.state.StoreId}&patientId=${this.state.patientId}&orederSetId=${this.state.orederSetId}&careProtoColId=${0}&medicineScreen=${'addFavMed'}&addedAssessmentList=${0}`}></iframe>
                <div className="modal-footer justify-content-center emrfrm pb-0">
                  <button type="button" className="btn btn-outline-secondary px-5  mr-2 text-uppercase" onClick={(e) => this.handleInteractionCancelFav(e)}>DON’T PROCEED</button>
                  <button type="button" className="btn btn-primary px-5 text-uppercase" onClick={(e) => this.handleInteractionOkFav(e)}>PROCEED TO ORDER</button>
                </div>
              </Modal>

              {/* Apply orderset  */}
              {this.state.showOrdersetModal ? (
                <Modal
                  title={<div className='pb-3'>Apply Order Set</div>}
                  visible={this.state.showOrdersetModal}
                  onCancel={(e) => this.handleOrderSetPopup(e, 'hide')}
                  footer={
                    <div className="col-12 pt-2 text-center">
                      <Button key="back" className="btn btn-outline-secondary px-5 cancelOrderSet" onClick={(e) => this.handleOrderSetPopup(e, 'hide')}>
                        CANCEL
                      </Button>
                      <Button key="submit" className="btn btn-primary px-5 deleteOrderSet" type="primary" disabled={this.state.diableConfrimButton} onClick={(e) => this.IsApplyOrdersets(e)}>
                        CONFIRM
                      </Button>
                    </div>
                  }
                  width="992px"
                  bodyStyle={{ height:"600px", overflowY:"auto" }}
                >


                  {this.state.showOrdersetModal ? (
                    <OrdersetList
                      formType={"copy-to-visit"}
                      selectOrderSet={this.selectOrderSet}
                      patient={this.state.patient}
                      selectedOrderSet={this.state.selectedOrderSet}
                    />
                  ) : null}
                </Modal>
              ) : null}
              <MedicineForm
                formType={"hidden"}
                actionText={this.state.actionText}
                updateData={this.updateMedicineData}
                loadData ={this.loadData}
                autofocus={false}
                /* selectedDrug={this.state.selectedDrug} */
                editData={this.state.editData}
                editIndex={this.state.editIndex}
                addedDrugsList={this.state.addedDrugsList}
                constants={this.state.constants}
                patient={this.state.patient}
                clearEdit={this.clearEdit}
                polyPharmacyWarningShown={this.state.polyPharmacyWarningShown}
                updatepolyPharmacyWarningShown={this.polyPharmacyWarningShown}
                ref={this.medFormRef}
                heightbmi={this.state.heightbmi}
                weightbmi={this.state.weightbmi}
                bmi={this.state.bmi}
                bsa={this.state.bsa}
              />
            </>
            :
            LOADER
        }
      </>
    );
  }
}

export default PlanMedicineComponentSS;